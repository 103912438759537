import { render, staticRenderFns } from "./RecipeIndex.vue?vue&type=template&id=7f222c8b&scoped=true&"
import script from "./RecipeIndex.vue?vue&type=script&lang=js&"
export * from "./RecipeIndex.vue?vue&type=script&lang=js&"
import style0 from "./RecipeIndex.vue?vue&type=style&index=0&id=7f222c8b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_vue-template-compiler@2.6.14/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f222c8b",
  null
  
)

export default component.exports