<template>
  <form method="POST" @submit.prevent="submit" enctype="multipart/form-data">
    <div class="form-row">
      <div class="col-12">
        <label for="select_category_label"
        >Select Category to mass update catalog items</label
        >
        <div>

          <multiselect
            label="category"
            track-by="id"
            :options="categories"
            v-model="selectedCategory"
            :key="categories.category"
            :taggable="false"
            :searchable="true"
            :close-on-select="true"
            placeholder="Select Category"
          />
        </div>
      </div>
    </div>
    <br/>

    <div class="form-row">
      <div class="col-4">
        <div class="form-group">
          <label for="sell_by_days">Sell By Days</label>
          <div class="input-group date">
            <input
              id="sell_by_days"
              name="sell_by_days"
              type="text"
              v-model="catalog.sell_by_days"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('sell_by_days')}"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('sell_by_days')"
            >
              {{ form.$errors.first('sell_by_days') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="use_by_days">Use By Days</label>
          <div class="input-group date">
            <input
              id="use_by_days"
              name="use_by_days"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('use_by_days')}"
              v-model="catalog.use_by_days"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('use_by_days')"
            >
              {{ form.$errors.first('use_by_days') }}
            </div>
          </div>

        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="packed_date">Packed Date</label>
          <select id="packed_date" name="packed_date" class="custom-select" :class="{ 'is-invalid' : form.$errors.has('packed_date')} " v-model="catalog.packed_date">
            <option :value="null"> Select Option</option>
            <option :value="'No'">No</option>
            <option :value="'Standard'">Standard</option>
            <option :value="'Coded'">Coded</option>
          </select>
        </div>
        <div
          class="invalid-feedback"
          v-show="form.$errors.has('packed_date')"
        >
          {{ form.$errors.first('packed_date') }}
        </div>
      </div>
    </div>

    <ul class="nav nav-pills plan-toggle d-flex justify-content-center align-items-center mb-3">
      <li>
        <a href="#" :class="{'active': isCustomLabelActive}" data-toggle="pill" @click="showCustomLabel(true)">Show Custom Fields</a>
      </li>
      <li>
        <a href="#" :class="{'active': !isCustomLabelActive}" data-toggle="pill" @click="showCustomLabel(false)">Hide Custom Fields</a>
      </li>
    </ul>

    <div class="form-row" v-if="isCustomLabelActive">
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 1</label>
          <div class="input-group date">
            <textarea
              id="custom_field_1"
              name="custom_field_1"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_1')}"
              v-model="catalog.custom_field_1"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_1')"
            >
              {{ form.$errors.first('custom_field_1') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 2</label>
          <div class="input-group date">
            <textarea
              id="custom_field_2"
              name="custom_field_2"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_2')}"
              v-model="catalog.custom_field_2"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_2')"
            >
              {{ form.$errors.first('custom_field_2') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 3</label>
          <div class="input-group date">
            <textarea
              id="custom_field_3"
              name="custom_field_3"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_3')}"
              v-model="catalog.custom_field_3"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_3')"
            >
              {{ form.$errors.first('custom_field_3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 4</label>
          <div class="input-group date">
            <textarea
              id="custom_field_4"
              name="custom_field_4"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_4')}"
              v-model="catalog.custom_field_4"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_4')"
            >
              {{ form.$errors.first('custom_field_4') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 5</label>
          <div class="input-group date">
            <textarea
              id="custom_field_5"
              name="custom_field_5"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_5')}"
              v-model="catalog.custom_field_5"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_5')"
            >
              {{ form.$errors.first('custom_field_5') }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="custom_field_1">Custom Field 6</label>
          <div class="input-group date">
            <textarea
              id="custom_field_6"
              name="custom_field_6"
              type="text"
              class="form-control"
              :class="{ 'is-invalid' : form.$errors.has('custom_field_6')}"
              v-model="catalog.custom_field_6"
            />
            <div
              class="invalid-feedback"
              v-show="form.$errors.has('custom_field_6')"
            >
              {{ form.$errors.first('custom_field_6') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-6">
          <label>Label Formats</label>
          <div class="form-check mb-1">
            <input
              id="clearAll"
              name="no_label"
              type="checkbox"
              class="form-check-input"
              v-model="catalog.no_label"
              :value="catalog.no_label"
              @click="clearAll"
            />
            <label class="form-check-label mt-1" for="clearAll">No Label</label>
          </div>
        </div>
        <div class="col-6 text-center">
          <label>Default Label Format</label>
        </div>
      </div>

      <div class="row" v-for="labelFormat in labelFormats" :key="labelFormat.id">
        <div class="col-6 label-format-wrapper">
          <div class="form-check mb-1">
            <input
              :id="`labelFormat-${labelFormat.id}`"
              name="label_formats[]"
              type="checkbox"
              class="form-check-input"
              :class="{ 'is-invalid' : form.$errors.has('packed_date')}"
              v-model="catalog.label_formats"
              :value="labelFormat.id"
              @click="selectLabelFormat(labelFormat.id)"
            >
            <label
              :for="`labelFormat-${labelFormat.id}`"
              :class="labelFormat.id === catalog.default_label_format ? 'text-danger' : ''"
              class="form-check-label mt-1 label-format-options"
            >
              {{ labelFormat.name }}
            </label>
          </div>
        </div>
        <div class="col-6 text-center">
          <input
            name="default_label_format"
            type="radio"
            :ref="'label-format-radio-button-'+labelFormat.id"
            v-model="catalog.default_label_format"
            :value="labelFormat.id"
            :disabled="!catalog.label_formats.includes(labelFormat.id)"
          >
        </div>

      </div>

      <div
        class="invalid-feedback d-block"
        v-show="form.$errors.has('label_formats') || form.$errors.has('default_label_format')"
      >
        {{ form.$errors.first('label_formats') || form.$errors.first('default_label_format') }}
      </div>
    </div>

    <div class="form-group">
      <label for="print_statement_id">Print Statement</label>
      <select
        id="print_statement_id"
        name="print_statement_id"
        class="custom-select"
        v-model="catalog.print_statement_id"
        :class="{ 'is-invalid' : form.$errors.has('print_statement_id')}"

      >
        <!--        <option-->
        <!--          v-for="statement in statements"-->
        <!--          :key="statement.id"-->
        <!--          :value="statement.id"-->
        <!--        >-->
        <!--          <strong>{{ statement.statement }}</strong>-->
        <!--        </option>-->


        <option :value="null">None</option>

        <option
          v-for="statement in statements"
          :key="statement.id"
          :value="statement.id"
        >
          <strong>{{ statement.statement }}</strong>
        </option>
      </select>
      <div
        class="invalid-feedback d-block"
        v-show="form.$errors.has('print_statement_id')"
      >
        {{ form.$errors.first('print_statement_id') }}
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-12">
          <label>Tare Weight Required</label>
          <div class="form-check mb-2">
            <input
              type="checkbox"
              id="is_tare_weight_required"
              name="is_tare_weight_included"
              class="form-check-input"
              v-model="catalog.is_tare_weight_included"
            />
            <label for="is_tare_weight_required" class="form-check-label mt-1">
              Require user to enter container tare weight
            </label>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-primary" @click="showConfirmMassUpdateCatalogModal">Update</button>
    <!-- Modal -->



    <div class="modal fade bd-example-modal-lg mass-update-modal" id="massUpdateCatalogModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
         >
      <div class="modal-dialog modal-lg h-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle"> Follwing items will not be updated due to some errors. </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div v-if="catalogsNotUpdatedDueToDescription.length">
                <div>
                  <h5>Items that can't be updated due to their description length.</h5>
                </div>
                <ul>
                  <li class="text-danger" style="font-size: 15px;" v-for="catalog in catalogsNotUpdatedDueToDescription">
                    {{catalog.name}}
                  </li>
                </ul>
              </div>

            <div v-if="catalogsNotUpdatedDueToSku.length">
              <div>
                  <h5>                Items that can't be updated due to their sku length.
                  </h5>
              </div>
              <ul>
                <li class="text-danger" style="font-size: 15px;" v-for="catalog in catalogsNotUpdatedDueToSku">
                  {{catalog.name}}
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-header">
            <div class="text-danger" style="font-size: 16px;">Note: Press Cancel and Return to select another label.</div>
          </div>
          <div class="modal-header">
            <h5>Do you still want to mass update the items ?</h5>
          </div>
          <div class="modal-footer">
            <form method="POST" @submit.prevent="updateCatalogs" enctype="multipart/form-data">
              <button type="submit" class="btn btn-primary">Continue and Update</button>
            </form>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel and Return</button>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<script>
import Multiselect from "vue-multiselect";
import Form from 'laravel-form-validation';


// register globally
Vue.component("multiselect", Multiselect);

export default {
  // OR register locally
  components: {Multiselect},
  data() {
    return {
      catalogsNotUpdatedDueToDescription:[],
      catalogsNotUpdatedDueToSku: [],
      errorType: '',
      params: {
        page: 1,
        search: null,
        per_page: 15,
        category_ids: [],
        sortBy: 'name',
        orderBy: 'asc',
      },
      value: null,
      options: ["list", "of", "options"],
      selectedCategory: null,
      form: new Form(),
      catalog: {
        sell_by_days : '' ,
        use_by_days : '' ,
        packed_date: null,
        label_formats: [],
        is_tare_weight_included : 0,
        print_statement_id: null,
        custom_field_1: null,
        custom_field_2: null,
        custom_field_3: null,
        custom_field_4: null,
        custom_field_5: null,
        custom_field_6: null,
      },
      catalogsNotUpdated : [],
      isCustomLabelActive: false,
    };
  },
  props: {

    categories: {
      type: Array,
    },
    statements: {
      type: Array,
    },
    labelFormats: {
      type: Array,
    },

  },
  methods: {
    closeCatalogueEditForm() {
      $('#massUpdateCatalogModal').modal('hide');
      window.location.href = route('admin.catalog.index');
    },

    async showConfirmMassUpdateCatalogModal() {
      const confirm = await this.$bvModal.msgBoxConfirm('All current parameters for the items in this category will be overwritten with the new parameters! Are you sure you want to proceed?\n' +
        '\n',{
        title: 'Are you sure?',
        okTitle: 'Update',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }
      this.submit();
    },

    updateCatalogs(){
      var category_id = this.selectedCategory.id;
      var catalogsNotUpdated = this.catalogsNotUpdatedDueToDescription.concat(this.catalogsNotUpdatedDueToSku);
      this.form.put(route('admin.massUpdateCatalog' ,category_id), {...this.catalog, 'catalogNotUpdated': catalogsNotUpdated}
      ).then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            this.closeCatalogueEditForm();
            this.$toast.success("Catalog updated successfully");
          }
      });
    },
    submit() {
      if (!this.selectedCategory) {
        this.$toast.error('Please select a category')
        return;
      }
      var category_id = this.selectedCategory.id;
      this.form.put(route('admin.findNotUpdatableCatalogs', category_id), this.catalog
      ).then((response) => {
        if(response.status === 400){
          $('#massUpdateCatalogModal').modal('show');
          this.catalogsNotUpdatedDueToDescription = response.$catalogsNotUpdatedDueToDescription ? response.$catalogsNotUpdatedDueToDescription : [];
          this.catalogsNotUpdatedDueToSku = response.$catalogsNotUpdatedDueToSku ? response.$catalogsNotUpdatedDueToSku : [];
        }else{
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            window.location.href = route('admin.catalog.index');
            this.$toast.success("Catalog updated successfully");
          }
        }
      })
        .catch((error) => {
          console.log(error);
        });
    },
    selectLabelFormat(labelFormatId) {
      if (this.catalog.label_formats.length === 0) {
        this.catalog.default_label_format = labelFormatId;
      }
    },

    clearAll() {
      this.catalog.no_label = ! this.catalog.no_label;
      if (this.catalog.no_label) {
        this.catalog.label_formats = [];
        this.catalog.default_label_format = null;
      }
    },
    showCustomLabel(isActive) {
      this.isCustomLabelActive = !!isActive;
    }
  },
  watch: {
    initial(newValue) {
      this.catalog = Object.assign({}, newValue, {
        label_formats: this.catalogueLabelFormats(newValue.labels) || [],
        default_label_format: this.catalogDefaultLabelFormat(newValue.labels),
        no_label: false
      });
    }
  }
};
</script>

<style scoped>
.plan-toggle {
  width: 330px;
  background: #f5f5f5;
  margin: 0;
  border-radius: 28px;
  border: 3px solid #f5f5f5;
  text-align: center;
}

.plan-toggle a {
  padding: 5px;
  font-size: 13px;
  border-radius: 28px;
}

.plan-toggle li {
  float: none;
  width: 50%;
}

.plan-toggle li a.active {
  background: #0E54EA;
  color: #ffffff;
}

.label-format-wrapper {
  word-break: break-word;
}
.modal.mass-update-modal {
  overflow: hidden;
}
.modal.mass-update-modal .modal-content {
  overflow-y: auto; 
}
</style>

