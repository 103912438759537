<template>
    <div class="content-wrapper">
        <section class="content">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h3 class="card-title">Merchant List</h3>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                                <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2" v-model="params.per_page">
                                    <option disabled>Per page</option>
                                    <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                                </select>

                                <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search by user" name="search"
                                       v-model.trim="params.search">
                                <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2" :disabled="processing">
                                    <i class="fa fa-search fa-fw"/>
                                </button>
                              <a class="btn btn-success mb-0 mb-sm-0 mr-sm-5" :href="route('admin.merchant.export', {'type': 'csv'})"> Export Merchant List</a>
                                <button
                                  class="btn btn-outline-primary mb-0 mb-sm-0 mr-sm-2 ml-auto"
                                  :disabled="processing"
                                  @click="importMerchantCatalog(authUser.id)"
                                >
                                  Re-import Catalog
                                  <i class="fas fa-sync"></i>
                                </button>
                                <div>
                                    <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing" aria-label="Loading"/>
                                </div>
                            </form>
                            <div class="table-wrapper table-responsive">
                            <table id="table" class="table table-striped table-bordered table-hover mb-2" >
                                <thead>
                                <tr>
                                    <th v-for="(column, key, index) in columns" :key="index">
                                        <span v-if="!nonSortableColumns.includes(key)">
                                            <a href="#" @click="sort(key)"> {{ column }} </a>
                                        </span>
                                        <span v-else>
                                            {{ column }}
                                        </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="sortedAdmins.length > 0">
                                <tr v-for="(admin, index) in sortedAdmins" :key="index">
                                  <td>{{ admin.id }}</td>
                                    <td>
                                        {{ admin.email }}
                                        <i class="fas fa-check-circle text-success" v-if="admin.email_verified_at"/>
                                        <i class="fas fa-info-circle text-danger" v-else/>
                                    </td>
                                    <td>
                                        {{ admin.name }}
                                        <h6 v-if="admin.profile" class="text-info mt-1">
                                            <i class="fas fa-store fa-fw"/>{{ admin.profile.business_name }}
                                        </h6>
                                        <div>
                                            Merchant ID : {{ admin.square_merchant_id }}
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="admin.profile">
                                            {{ businessStatus(admin.profile.status) }}
                                        </span>
                                    </td>
                                    <td>{{ admin.current_subscription }}</td>
                                    <td>{{ formatCreatedAt(admin.created_at) }}</td>
                                    <td>{{ formatUpdatedAt(admin.updated_at) }}</td>
                                    <td>
                                        <div class="btn-group btn-group-sm">
                                            <button
                                                class="btn btn-sm btn-primary"
                                                :disabled="processing"
                                                @click="editMerchant(admin)"
                                            >
                                                <i class="fas fa-edit"></i>
                                            </button>
                                            <button
                                                class="btn btn-sm btn-danger"
                                                :disabled="processing"
                                                @click="removeMerchant(admin)"
                                            >
                                                <i class="fas fa-trash"></i>
                                            </button>
                                            <button
                                                class="btn btn-sm btn-secondary"
                                                :disabled="processing"
                                                @click="importMerchantCatalog(admin.id)"
                                            >
                                                <i class="fas fa-sync"></i>
                                            </button>
                                          <button
                                            class="btn btn-sm btn-success"
                                            :disabled="processing"
                                            @click="impersonateAdmin(admin.id)"
                                          >
                                            <i class="fas fa-sign-in-alt"></i>
                                          </button>
                                          <button
                                            class="btn btn-sm btn-dark"
                                            :disabled="processing"
                                            @click="syncMerchant(authUser,response.data[index])"
                                          >
                                            Sync
                                          </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot v-else>
                                    <td colspan="7">
                                    <div class="text-center">
                                        <strong>No Results Found</strong>
                                    </div>
                                    </td>
                                </tfoot>
                            </table>
                            </div>
                            <div class="text-center">
                                    <pagination class="mb-0"
                                                @pagination-change-page="pageNavigated"
                                                :data="response"
                                                :show-disabled="true"
                                                :limit="2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <merchant-edit-modal
            :initial="selectedMerchant"
            @updated="onMerchantUpdated"
        ></merchant-edit-modal>
    </div>
</template>

<script>
import MerchantEditModal from '@/js/Components/Merchant/MerchantEditModal.vue';
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';

export default {
    name: "MerchantIndex",
    components: {
        Pagination,
        MerchantEditModal
    },
    props: {
        initial: {
            type: Object,
        },
        authUser: {
            type: Object,
        },
    },
    data() {
        return {
            processing: false,
            sortKey: "name",
            sortOrder: "asc",
            params: {
                page: 1,
                search: null,
                per_page: 15,
            },
            response: {},
            columns: {
                id: 'Admin Id',
                email: 'Email',
                name: 'Name',
                status: 'Status',
                subscription: 'Subscription',
                created_at: 'Created At',
                updated_at: 'Updated At',
                action: 'Action'
            },
            nonSortableColumns: ['action', 'status', 'subscription'],
            selectedMerchant: {}
        }
    },
    created() {
        this.response = this.initial;
    },
    methods: {
        async syncMerchant(authUser,merchant) {
          this.processing = true;
          this.$http.put(route('admin.merchant.syncMerchant', merchant.id))
            .then(response => {
              if(response.data){
                this.$toast.success(response.data);
              }
            })
            .catch(error => {
              console.error(error)
              this.$toast.error('Something went wrong.')
            })
            .finally(() => {
              this.processing = false;
            })
        },
        sort(sortKey) {
            if (sortKey === this.sortKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            }
            this.sortKey = sortKey;
        },
        pageNavigated(page = 1) {
            this.params.page = page;
            this.fetch();
        },
        fetch() {
            this.processing = true;
            this.$http.get(route('admin.merchant.index'), {
                params: this.params,
            })
                .then((response) => {
                    console.log(response.data);
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        formatCreatedAt (dateTime) {
          return dayjs.utc(dateTime).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
        },
        formatUpdatedAt (dateTime) {
          return dayjs.utc(dateTime).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
        },
        async removeMerchant($merchant) {
            const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to
                delete this Merchant Account? All data associated with this account will be deleted.
                This action can not be undone!`, {
                title: 'Are you sure?',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
            })

            if (!confirm) {
                return
            }

            this.processing = true;
            this.$http.delete(route('admin.merchant.delete', $merchant))
                .then(response => {
                    console.log(response)
                    this.$toast.success('Merchant removed successfully.')
                    this.fetch();
                })
                .catch(error => {
                    console.error(error)
                    this.$toast.error('Something went wrong.')
                })
                .finally(() => {
                    this.processing = false;
                })

        },

      async impersonateAdmin($id) {
        this.processing = true;
        this.$http.post(route('admin.merchant.impersonate.start', $id))
          .then(response => {
            window.location = route('admin.dashboard');
          })
          .catch(error => {
            console.error(error)
            this.$toast.error('Something went wrong.')
          })
          .finally(() => {
            this.processing = false;
          })
      },

      async importMerchantCatalog($id) {
        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to
                Re-import this Merchant's Catalog?`, {
          title: 'Are you sure?',
          okVariant: 'primary',
          okTitle: 'Import',
          cancelTitle: 'Cancel',
        })

        if (!confirm) {
          return
        }

        this.processing = true;
        this.$http.post(route('admin.merchant.import-catalog', $id))
          .then(response => {
            console.log(response)
            this.$toast.success('Re-importing Merchant catalog.')
            this.fetch();
          })
          .catch(error => {
            console.error(error)
            this.$toast.error('Something went wrong.')
          })
          .finally(() => {
            this.processing = false;
          })

      },

        editMerchant(merchant) {
            this.selectedMerchant = Object.assign({}, merchant)
            this.$nextTick(() => {
                this.$bvModal.show('edit-merchant-modal');
            })
        },
        onMerchantUpdated() {
            this.$bvModal.hide('edit-merchant-modal');
            this.fetch();
        },
        businessStatus(status) {
            return status ? 'Active' : 'Inactive';
        }
    },

    computed: {
        admins: function () {
            return this.response.data;
        },
        sortedAdmins: function () {
            return this.admins.sort((a, b) => {
                let modifier = 1;
                if (this.sortOrder === "desc") modifier = -1;
                if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
                if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
                return 0;
            });
        },
    },
};
</script>
