<template>
    <div class="content-wrapper">
        <section class="content">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3 class="card-title">Catalog List</h3>
                        </div>
                        <div class="card-body">
                            <div class="card-body float-right">
                                <div class="dropdown mb-0">
                                    <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="tools"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-tools"></i> Actions
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
                                        <a class="dropdown-item" :href="route('admin.export', '.csv')">
                                            <i class="fas fa-file-csv"></i> Export as CSV
                                        </a>
                                        <a class="dropdown-item" :href="route('admin.export', '.xlsx')">
                                            <i class="fas fa-file-excel"></i> Export as Excel
                                        </a>
                                        <span class="dropdown-item sync-button" @click="sync()">
                                            <i class="fas fa-file-import"></i> Sync Catalog
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <form id="search-form" class="form-inline mt-4 mb-2" @submit.prevent="pageNavigated(1)">
                                <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                                        v-model="params.per_page">
                                    <option disabled>Per page</option>
                                    <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                                </select>
                                <b-dropdown variant="light" left>
                                    <template v-slot:button-content>
                                        <span v-if="params.category_ids.length === 0">
                                            All Categories
                                        </span>
                                        <span v-else>
                                            {{ params.category_ids.length }} Selected
                                        </span>
                                    </template>
                                    <div class="category-list">
                                        <div class="form-group" v-if="categories.length === 0">
                                          <label class="form-check-label ml-4">The list is empty.</label>
                                        </div>
                                        <div v-for="category in categories" :key="category.id" class="dropdown-item">
                                            <div class="form-group">
                                                <input type="checkbox" class="form-check-input"
                                                       v-model="params.category_ids" :value="category.id"
                                                       :id="`category-${category.id}`">
                                                <label class="form-check-label"
                                                       :for="`category-${category.id}`">{{ category.category }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </b-dropdown>
                                <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search"
                                       name="search"
                                       v-model.trim="params.search">
                                <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2"
                                        :disabled="processing">
                                    <i class="fa fa-search fa-fw"/>
                                </button>
                                <div>
                                    <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing"
                                       aria-label="Loading"/>
                                </div>
                            </form>
                            <div class="table-responsive table-wrapper">
                            <table id="table" class="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            Image
                                        </th>
                                        <th v-for="(column, key, index) in columns" :key="index">
                                            <span v-if="!notSortableColumns.includes(key)">
                                                <a href="#" @click="sort(key)">{{ column }}</a>
                                            </span>
                                            <span v-else>
                                                {{column}}
                                            </span>
                                        </th>
                                        <th v-if="hasDeliLabelSubscription">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="catalogs.length > 0 &&  syncingStatus === 2">
                                <tr v-for="(catalog, catalogIndex) in catalogs" :key="catalogIndex">
                                    <td>
                                        <catalogue-image-card :catalog="catalog" :thumbnailClass="thumbnailClass"/>
                                    </td>

                                    <td>
                                        {{ catalog.name }}
                                        <div v-if="catalog.locations" class="text-left  my-3">
                                            <span :id="`popover-target-${catalogIndex}`" class="badge badge-secondary">
                                                Locations <span class="badge badge-light">{{catalog.locations.length}}</span>
                                            </span>
                                            <b-popover :target="`popover-target-${catalogIndex}`" triggers="hover" placement="right">
                                                <template #title>Locations</template>
                                                <div v-for="(location,index) in catalog.locations" :key="index">
                                                    {{ location.name }}
                                                </div>
                                            </b-popover>
                                        </div>
                                    </td>
                                    <td>{{ catalog.category.category}}</td>
                                    <td>
                                        <div
                                            v-for="(variations, variationIndex) in catalog.variations_data"
                                            :key="variationIndex">
                                            <span
                                                v-for="(variation, index) in variations"
                                                :key="variation.id">
                                                <div
                                                    v-if="index === 'item_variation_data'"
                                                    :class="variation.sku === params.search ? 'text-info' : ''"
                                                >
                                                    {{ getSku(variation) }}
                                                </div>
                                            </span>
                                        </div>
                                    </td>
                                    <td>{{ catalog.sell_by_days }}</td>
                                    <td>{{ catalog.use_by_days }}</td>
                                    <td>{{ catalog.packed_date }}</td>
                                    <td>
                                        <div class="form-check mb-2">
                                            <input
                                                type="checkbox" :id="`tareWeight-${catalog.id}`"
                                                name="is_tare_weight_included"
                                                class="form-check-input"
                                                @change="updateTareWeightStatus(catalog)"
                                                v-model="catalog.is_tare_weight_included"
                                            >
                                            <label :for="`tareWeight-${catalog.id}`" class="form-check-label mt-1">
                                                Require user to enter <br> container tare weight
                                            </label>
                                        </div>
                                    </td>
                                    <td v-if="catalog.labels">
                                        <div v-for="(label, index) in catalog.labels" :key="index">
                                            <div v-if="label.pivot.is_primary == 1">
                                                <span :class="label.pivot.is_primary ? 'text-info' : ''">
                                                    {{ label.name }}
                                                </span>
                                            </div>
                                            <div v-else>
                                                {{ label.name }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="catalog.statement">
                                            {{catalog.statement.statement}}
                                        </div>
                                    </td>
                                    <td>{{ formatDateTime(catalog.updated_at) }}</td>
                                    <td v-if="hasDeliLabelSubscription">
                                        <div class="btn-group btn-group-sm">
                                            <button
                                                title="Edit"
                                                @click="editCatalogue(catalog)"
                                                class="btn btn-primary"
                                                :disabled="processing"
                                            >
                                                <i class="fas fa-edit"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot v-else>
                                <td colspan="12">
                                    <div class="text-center text-bold">
                                        <span v-if="syncingStatus === 0">
                                            Catalogue syncing is pending
                                        </span>
                                        <span v-else-if="syncingStatus === 1">
                                                <i class="mr-2 fas fa-spin fa-sync-alt text-muted"
                                                   aria-label="Loading"/>
                                                Catalogue syncing is in progress
                                        </span>
                                        <span v-else>
                                                No Results Found
                                        </span>
                                    </div>
                                </td>
                                </tfoot>
                            </table>
                        </div>
                            <div class="text-center" v-if="syncingStatus===2">
                                    <pagination class="mb-0"
                                                @pagination-change-page="pageNavigated"
                                                :data="response"
                                                :show-disabled="true"
                                                :limit="2"/>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <catalogue-edit-modal
            :initial="selectedCatalogue"
            :show-modal="isVisible"
            :is-custom-field-empty="isCustomFieldEmpty"
            :label-formats="labelFormats"
            :statements="statements"
            @closeCatalogueEditModal="closeCatalogueEditForm"
        ></catalogue-edit-modal>

    </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import CatalogueEditModal from '@/js/Components/Catalogue/CatalogueEditModal.vue';
import dayjs from 'dayjs';

export default {
    name: "catalogueIndex",
    components: {
        Pagination,
        CatalogueEditModal,
    },
    props: {
        initial: {
            type: Object,
        },
        categories: {
            type: Array,
        },
        user: {
            type: Object,
        },
        thumbnailClass: {
            type: String,
        },
        labelFormats: {
            type: Array,
        },
        statements: {
            type: Array,
        },
        hasDeliLabelSubscription: {
            type: Object
        }
    },
    data() {
        return {
            processing: false,
            sortKey: "name",
            params: {
                page: 1,
                search: null,
                per_page: 15,
                category_ids: [],
                sortBy: 'name',
                orderBy: 'asc',
            },
            response: {},
            columns: {
                'name': 'Name',
                'category_id': 'Category',
                'sku': 'SKU',
                'sell_by_days': 'Sell By Days',
                'use_by_days': 'Use By Days',
                'packed_date': 'Packed Date',
                'tare_weight': 'Is Tare Weight Required ?',
                'label_format': 'Label Format',
                'print_statement_id': 'Statement',
                'updated_at': 'Updated At'
            },
            selectedCatalogue: {},
            notSortableColumns: ['sku', 'locations', 'tare_weight', 'label_format', 'print_statement_id'],
            syncingStatus: this.user.is_account_synced,
            isVisible: false,
            isCustomFieldEmpty: false,
        }
    },
    created() {
        this.response = this.initial;
    },
    methods: {
        sort(sortKey) {
            if (sortKey === this.sortKey) {
                this.params.orderBy = this.params.orderBy === "asc" ? "desc" : "asc";
            }
            this.sortKey = sortKey;
            this.params.sortBy = sortKey;
            this.pageNavigated(1);
        },
        pageNavigated(page = 1) {
            this.params.page = page;
            this.fetch();
        },
        fetch() {
            this.processing = true;
            this.$http.get(route('admin.catalog.index'), {
                params: this.params,
            })
                .then((response) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        formatDateTime(dateTime) {
            return dayjs.utc(dateTime).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
        },
        getSku(variation) {
            return 'sku' in variation ? 'name' in variation
                ? `${variation.name} - ${variation.sku}`
                : variation.sku : '-';

        },
        sync() {
            this.processing = true
            this.$http.post(route('admin.catalogue.sync'))
                .then(() => {
                    this.$toast.success("Catalog has been synced successfully")
                    setTimeout(() => {
                        location.reload()
                    }, 2000)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.processing = false
                })
        },
        updateTareWeightStatus(catalog) {
            this.$http.put(route('admin.catalog.tareWeight', catalog), catalog)
                .then((response) => {
                    this.$toast.success("Tare weight status updated successfully");
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong')
                })
        },
        editCatalogue(catalogue) {
            this.selectedCatalogue = Object.assign({}, catalogue);
            this.isVisible = true;
            this.isCustomFieldEmpty = !(this.selectedCatalogue.custom_field_1 || this.selectedCatalogue.custom_field_2 || this.selectedCatalogue.custom_field_3
                || this.selectedCatalogue.custom_field_4 || this.selectedCatalogue.custom_field_5 || this.selectedCatalogue.custom_field_6);
        },
        closeCatalogueEditForm() {
            this.isVisible = false;
            this.fetch();
        }
    },
    computed: {
        catalogs: function () {
            return this.response.data;
        }
    },
};
</script>
