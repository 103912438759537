<template>
    <div>
        <subscription-card
            v-if="isTrialSubscription"
            :class="{'processing': processing === true}"
            :initial="subscriptions"
            :user-subscriptions="userSubscriptions"
            :stripe-products="stripeProducts"
            :user="user"
            :processing="processing"
            @subscribe="getSubscription"
        ></subscription-card>

        <payment-method-card
            v-else
            @cancelPayment="subscriptionIsTrial"
            :subscription="subscription"
            :coupon-code="couponCode"
            :coupons="coupons"
            :user="user"
            :zip-codes="zipCodes"
            :disable-subscriptions="disableSubscriptions"
        ></payment-method-card>
    </div>
</template>

<script>
import SubscriptionCard from '@/js/Components/Subscription/Modal/Cards/SubscriptionCard.vue';
import PaymentMethodCard from '@/js/Components/Subscription/Modal/Cards/PaymentMethodCard.vue';

export default {
    name: 'SubscriptionIndex',
    props: {
        user: {
            type: Object,
            required: true,
        },
        subscriptions: {
            type: Array,
            required: true,
        },
        zipCodes: {
            type: Object,
            required: true,
        },
        userSubscriptions: {
            type: Array,
            required: true
        },
      coupons: {
        type: Array,
        required: true
      },
      stripeProducts: {
          type: Object,
          required: true
      }
    },
    data() {
        return {
            isTrialSubscription: true,
            subscription: [],
            processing: false,
            disableSubscriptions: [],
            couponCode: null
        }
    },
    components: {
        SubscriptionCard,
        PaymentMethodCard,
    },
    created() {
      this.findDisableSubscription()
    },
    methods: {
        findDisableSubscription() {
          for( let subscription of this.userSubscriptions) {
            for( let [key, value] of Object.entries(this.stripeProducts)) {
              if(value.includes(subscription)) {
                this.disableSubscriptions.push(...value);
              }
            }
          }
        },
        getSubscription(subscription, couponCode) {
            if (!this.subscriptionIsTrial(subscription[0].price)) {
                this.subscription = subscription;
                this.couponCode = couponCode;
                return;
            }

            this.subscribe(subscription);
        },
        subscriptionIsTrial(subscriptionPrice = null) {
            this.isTrialSubscription = !Number(subscriptionPrice);
            return this.isTrialSubscription;
        },
        subscribe(subscription) {
            this.processing = true;
            this.$http.post(this.requestUrl(), subscription)
                .then((response) => {
                    this.$toast.success('Congratulations! your subscription has been started.');
                    window.location = route('admin.');
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong')
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        requestUrl() {
            return route('admin.subscriptions.subscribe.trial');
        }
    }
}
</script>
