<template>
  <div class="content-wrapper">
    <cook-history-show v-if="viewCookHistoryDetails"
                       :worklist="worklist"
                       :ingredients="ingredients"
                       @view-cook-history-index="viewCookHistoryIndex"/>
    <section v-else class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <h2 class="card-title mb-1 ml-2">Recipe Build History</h2>
            </div>
            <div class="card-body">
              <div class="row align-items-start mb-3">
                <div class="col-lg-12">
                  <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                            v-model="params.per_page">
                      <option disabled>Per page</option>
                      <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <b-dropdown variant="light" left class="mr-2">
                      <template #button-content>
                        <span>
                          All Categories
                        </span>
                      </template>
                      <div class="recipe-list">
                        <div class="form-group" v-if="categories.length === 0">
                          <label class="form-check-label ml-4">The list is empty.</label>
                        </div>
                        <div v-for="(category) in categories" :key="category.id" class="dropdown-item">
                          <div class="form-group">
                            <input type="checkbox" class="form-check-input"
                                   v-model="params.category_ids"
                                   :value="category.id"
                                   :id="`category-${category.id}`"
                            >

                            <label class="form-check-label"
                                   :for="`category-${category.id}`">{{ category.name }}</label>
                          </div>
                        </div>
                      </div>
                    </b-dropdown>
                    <div class="recipe-list date-filter">
                      <div class="form-group">
                        <label for="">From Date</label>
                        <input type="date" name="from_date" v-model="params.from_date" class="mb-2">
                      </div>
                      <div class="form-group ml-2">
                        <label for="">To Date</label>
                        <input type="date" name="to_date" v-model="params.to_date" class="mb-2">
                      </div>
                    </div>
                    <input type="text"
                           class="form-control mb-2 mb-sm-0 mr-sm-2"
                           placeholder="Search"
                           v-model="params.search"
                           name="search">
                    <button type="submit"
                            class="btn btn-primary mb-0">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </form>
                </div>
              </div>
              <div class="row align-items-center mb-3">
                <div class="col-12">
                  <table id="table" class="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-primary">
                          Image
                        </th>
                        <th class="text-primary">Name</th>
                        <th class="text-primary">Category</th>
                        <th class="text-primary">Recipe Yield</th>
                        <th class="text-primary">Batch Size</th>
                        <th class="text-primary">UOM</th>
                        <th class="text-primary">Employee Name</th>
                        <th class="text-primary">Completed At</th>
                        <th class="text-primary">
                          <a href="#">Actions</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="worklists.length > 0">
                      <tr v-for="(worklist, index) in worklists" :key="index">
                        <td>
                          <img :src="worklist.recipe.is_catalog_image ? worklist.recipe.catalog.image : worklist.recipe.image" class="ml-3" alt="">
                        </td>
                        <td>
                          {{ worklist.recipe.name }}
                        </td>
                        <td>
                          {{ worklist.recipe.recipe_category.name }}
                        </td>
                        <td>
                          {{ worklist.recipe.catalog.name }}
                        </td>
                        <td>
                          {{ worklist.batch_size }}
                        </td>
                        <td>
                          {{ getUnitOfMeasure(worklist.recipe.catalog, worklist.recipe.variation_data_id) }}
                        </td>
                        <td>
                          {{ worklist.employee ? worklist.employee.name : ''  }}
                        </td>
                        <td>
                          {{ formatDateTime(worklist.end_time) }}
                        </td>
                        <td>
                          <a data-toggle="dropdown" id="ellipsis-icon" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-h"/>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="tools">
                            <span class="dropdown-item sync-button" @click="viewHistory(worklist)">
                              View History
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <td colspan="9">
                        <div class="text-center">
                          <strong>No Results Found</strong>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                  <section class="text-center">
                    <div class="table-responsive-sm">
                      <pagination class="mb-0"
                                  @pagination-change-page="pageNavigated"
                                  :show-disabled="true"
                                  :data="response"
                                  :limit="2"/>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {scrollToTop} from '@/js/scrollToTop';
import dayjs from 'dayjs';
import Pagination from 'laravel-vue-pagination';
import CookHistoryShow from "@/js/KitchenHand/Components/History/Cook/CookHistoryShow";

export default {
  name: 'CookHistory',
  components: {CookHistoryShow, Pagination},
  props: {
    initial: {
      type: Object,
    },
    categories: {
      type: Array,
    },
  },
  created() {
    this.response = this.initial;
  },
  data() {
    return {
      params: {
        page: 1,
        search: null,
        per_page: 15,
        category_ids: [],
        from_date: '',
        to_date: '',
      },
      processing: false,
      response: {},
      viewCookHistoryDetails: false,
      worklist:'',
      ingredients: ''
    }
  },
  methods: {
    fetch() {
      this.processing = true;
      scrollToTop();
      this.$http.get(route('admin.kitchen.history.indexRecipeBuild'), {
        params: this.params,
      })
          .then((response) => {
            this.response = response.data;
          })
          .catch((error) => {
            this.$toast.error('Something went wrong');
          })
          .finally(() =>{
            this.processing = false;
          })
    },
    formatDateTime(dateTime) {
      return dayjs(dateTime).format('YYYY-MM-DD')
    },
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      // for checking square item having parent child
      if (/[,\-]/.test(variationsData.item_variation_data['name'])){
        variationsData.item_variation_data['name'] = variationsData.item_variation_data['name'].substring(
            variationsData.item_variation_data['name'].indexOf('-') + 2
        )
      }
      return variationsData.item_variation_data['name']
    },
    async viewHistory(worklist) {
      await this.$http.get(route('admin.kitchen.work-list.ingredients', worklist.id), {
      })
        .then((response) => {
          this.ingredients = response.data.recipe.ingredients;
        })
      this.viewCookHistoryDetails = true;
      this.worklist = worklist;
    },
    viewCookHistoryIndex() {
      this.viewCookHistoryDetails = false;
    }
  },
  computed: {
    worklists: function () {
      return this.response.data;
    }
  },
}
</script>
<style scoped>
td > a > i {
  cursor: pointer;
}

.date-filter {
  /* padding: 10px; */
  margin: -10px 10px 0 10px;
  display: flex;
}
.date-filter input {
  width: 100%;
}

.date-filter input {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 12px;
  color: #495057;
}

@media(max-width: 767px) {
  .date-filter {
    margin: 10px 10px 0 0;
  }
}

</style>
