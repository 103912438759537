<template>
  <div class="card mt-2 add-recipe" >
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Edit Step</h2>
      <button class="btn btn-secondary ml-auto" @click="goBack">Back</button>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="step-title mb-4">
          <h5>Step Number: {{ stepNumber + 1 }}</h5>
        </div>
        <div class="row">
          <div class="col-xl-9 col-lg-7 details-field">
            <form class="w-100" style="display: flex" enctype="multipart/form-data">
              <div class="col-12">
                <div class="row">
                  <div class="col-5">
                    <div class="form-group">
                      <label for="name">Title of the Step</label>
                      <input
                        name="title"
                        type="text"
                        class="form-control"
                        v-model="step.title"
                        placeholder="Enter title"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="title"/>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for="name">Step Timer</label>
                      <select
                        name="timer"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-model="step.timer"
                        v-invalid="form.$errors"
                      >
                        <option value="" disabled selected>
                          Select a Category
                        </option>
                        <option v-for="(timer) in stepTimers" :value="timer.value">{{ timer.message }}</option>
                      </select>
                      <field-error :bag="form.$errors" field="timer"/>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="name">Timer in Minutes</label>
                      <input
                        type="number"
                        name="timer_in_minutes"
                        class="form-control mb-2 mb-sm-0 mr-sm-2"
                        v-model="step.timer_in_minutes"
                        :disabled="parseInt(step.timer) === 0"
                        v-invalid="form.$errors"
                        min="1"
                      >
                      <field-error :bag="form.$errors" field="timer_in_minutes"/>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="name">Instruction</label>
                      <textarea
                        name="instruction"
                        class="form-control"
                        v-model="step.instruction"
                        placeholder="Add Step Instruction"
                        rows="14"
                        v-invalid="form.$errors"
                      />
                      <field-error :bag="form.$errors" field="instruction"/>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="col-xl-3 col-lg-5 image-field">
            <div>
              <div class="form-group img-wrapper mx-auto my-4" id="img-wrapper">
                <img width="386px;"
                     v-if="imagePreview"
                     :src="imagePreview"
                     alt="recipe_image" >
              </div>
              <label v-if="Object.keys(form.$errors.errors).length > 0 && form.$errors.errors.image !== undefined" class="text-danger invalid-error">{{ form.$errors.errors.image[0] }}</label>
              <div class="form-group">
                <button
                  class="btn btn-outline-primary btn-block select-btn-custom"
                  @click="$refs.file.click()"
                >
                  Select an image of the Recipe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 add-ingredient-wrapper">
              <h5>Add Ingredients</h5>
              <div>
                <label for="" class="form-label">Select By : </label>
                <label class="ml-2">
                  Name <input type="radio" value="name" v-model="catalogSelectBy">
                </label>

                <label class="ml-2">
                  SKU <input type="radio" value="sku" v-model="catalogSelectBy">
                </label>
                <div v-if="catalogSelectBy === 'sku'" class="row">
                  <input type="text"
                         class="form-control col-3"
                         placeholder="Search by SKU"
                         v-model="skuValue">
                  <button type="button"
                          @click="searchBySku"
                          class="btn btn-primary ml-2">
                    <i class="fa fa-search fa-fw"/>
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-1">
                  <div class="row">
                    <div class="col-2">
                      <div class="form-group">
                        <label>Image</label>
                        <img :src="ingredient.catalog ? ingredient.catalog.image : '/img/no-image.png'" class="ingredient-image" alt="ingredient-image">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label>Select an Item</label>
<!--                        <select class="form-control" v-model="ingredient.catalog_id">-->
<!--                          <option v-for="(item, index) in stepItems" :key="index" :value="item.id">{{ item.name }}</option>-->
<!--                        </select>-->
                        <multiselect
                          id="catalog_id"
                          name="catalog_id"
                          label="nameWithCategory"
                          track-by="id"
                          v-model="ingredient.catalog"
                          @input="changeSelectBy"
                          :options="stepItemsWithCategory"
                          :taggable="false"
                          :searchable="true"
                          :close-on-select="true"
                          placeholder="Select Ingredient"
                          @remove="unselectIngredient"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Unit Of Measure</label>
                        <select class="form-control"
                                v-model="ingredient.variation_data_id"
                                @change="findSku(ingredient.variation_data_id)"
                        >
                          <option v-for="(unit_of_measure) of recipeUnitOfMeasures" :value="unit_of_measure.id">{{ unit_of_measure.value }}</option>
                        </select>
                        <div class="dynamic-label mt-3" v-if="ingredient.variation_data_id && this.recipeUnitOfMeasures.find((uom) => uom.id === ingredient.variation_data_id)">
                          <label>{{ 'Sku:' + recipeYieldSku }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-5">
                  <div class="row">
                    <div class="col-3">
                      <div class="form-group">
                        <label>Qty</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="ingredient.quantity"
                          min="1"
                        >
                        <label class="text-danger" v-if="showErrors">Qty must be greater than 1</label>
                        <div class="dynamic-label mt-3" v-if="ingredient.variation_data_id && this.ingredient.quantity && this.recipeUnitOfMeasures.find((uom) => uom.id === ingredient.variation_data_id)">
                          <label>{{ 'Price: $ ' + itemPrice }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div class="form-group">
                        <button class="btn btn-primary mt-4 ml-2" @click="addIngredient()" :disabled="ingredient.variation_data_id === '' || ingredient.variation_data_id === undefined || ingredient.quantity === ''">
                          Add Ingredient
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3" v-if="step.ingredients.length > 0">
            <div class="col-12 my-5 ingredient-table-wrapper">
              <table class="table table-bordered ingredient-table">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">UOM</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <draggable v-model="step.ingredients" @change="changeIngredientOrder" tag="tbody">
                  <tr v-for="(stepIngredient, index) in step.ingredients" :key="index">
                    <td>
                      <img :src="stepIngredient.catalog.image" class="ingredient-image" alt="">
                    </td>
                    <td>{{ stepIngredient.catalog.name }}</td>
                    <td>
                      {{ (Object.keys(stepIngredient.unit_of_measure).length > 0) ? (stepIngredient.unit_of_measure.item_variation_data.name + '-' + (stepIngredient.unit_of_measure.item_variation_data.price_money ? stepIngredient.unit_of_measure.item_variation_data.price_money.amount/100.00 : 0)) : '' }}
                    </td>
                    <td>{{ stepIngredient.quantity }}</td>
                    <td>
                      <div class="btn-group btn-group-sm">
                        <button title="Edit" class="btn btn-primary" @click="showEditIngredientModal(index)"><i class="fas fa-edit"></i></button>
                        <button title="Delete" @click="deleteIngredient(stepIngredient, index)" class="btn btn-danger ml-1" ><i class="fas fa-trash"></i></button>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>

          <button type="button" class="btn btn-primary float-right mt-3 mr-4" @click="updateRecipe" :disabled="processing">Update</button>
        </div>
      </div>
    </div>
    <input type="file" ref="file" name="image" @change="onFileChange" v-invalid="form.$errors" hidden/>

    <step-ingredient-edit-modal v-if="ingredientEditModalIsVisible"
                                :initial="step"
                                :ingredient-number="ingredientId"
                                :ingredients="initialStepItems"
                                @ingredientUpdated="updateIngredient"
                                @closeModal="closeModal"
                                @ingredientDetailsUpdated="updateIngredientDetails"
    />
  </div>
</template>

<script>

import Form from 'laravel-form-validation';
import StepIngredientEditModal from '@/js/KitchenHand/Components/Recipe/StepIngredientEditModal';
import Multiselect from 'vue-multiselect';
import draggable from 'vuedraggable';

export default {
  name: 'RecipeStepEdit',
  components: {StepIngredientEditModal, Multiselect, draggable},

  props: {
    initial: {
      type: Object,
    },
    items: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    stepNumber: {
      type: Number,
    },
    recipe: {
      type: Object,
    },
    stepTimers: {
      type: Object,
    },
  },
  data() {
    return {
      currentStep: 1,
      yield: this.recipe.yield,
      step: {

      },
      ingredient: {
        step_id: '',
        name: '',
        catalog_id: null,
        unit_of_measure: '',
        variation_data_id: '',
        quantity: 1,
      },
      processing: false,
      imagePreview: null,
      recipePreviewIsVisible: false,
      form: new Form,
      ingredientEditModalIsVisible: false,
      currentStepSaved: false,
      stepId: '',
      ingredientId: 0,
      stepItems: [],
      unitOfMeasures: [],
      showErrors: false,
      showErrorsInModal: false,
      timers: ['No Control', 'User Timer,Must Wait', 'User Timer,Must Advance'],
      recipeYieldSku: '',
      initialStepItems: [],
      catalogSelectBy: 'name',
      skuValue: '',
    };
  },
  methods: {
    addIngredient() {
      if (this.ingredient.quantity <= 0 || this.ingredient.quantity > 9999.99) {
        this.$toast.error('Quantity must be greater than 0 and less than 9999.99.')
        return;
      }
      if (!this.recipeYieldSku) {
        this.$toast.error('Can\'t add ingredient with the undefined sku');
        return;
      }
      this.ingredient.number = this.step.ingredients.length + 1;
      this.ingredient.quantity = this.ingredient.quantity ? parseFloat(this.ingredient.quantity).toFixed(2) : this.ingredient.quantity;
      this.processing = true;
      let ingredients = this.step.ingredients.map((ingredient) => ingredient.name);
      this.processing = true;
      this.form.post(route('admin.kitchen.ingredient.store', this.step.id), this.ingredient)
        .then((response) => {
          this.step.ingredients.push(response);
          this.stepItems = this.stepItems.filter((stepItem) => stepItem.id !== this.ingredient.catalog_id)
          this.ingredient = {
            step_id: this.currentStep,
            catalog_id: null,
            name: '',
            unit_of_measure: '',
            variation_data_id: '',
            quantity: 1,
          }
        })
        .catch((error) => {
          console.log(error)
          this.showErrors = false;
        })
        .finally(() => {
          this.processing = false;
        })
    },
    showEditIngredientModal(index) {
      this.ingredientId = index
      this.ingredientEditModalIsVisible = true;
    },
    async deleteIngredient(stepIngredient, index) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this ingredient?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }
      if(stepIngredient.id === undefined) {
        this.step.ingredients.splice(index,1)
        this.$toast.success('Ingredient has been deleted')
      }
      else{
        this.$http.delete(route('admin.kitchen.ingredient.destroy', stepIngredient.id))
          .then((response) => {
            if (response) {
              this.step.ingredients.splice(index,1)
              this.$toast.success('Ingredient has been deleted')
            }
          })
          .catch((error) => {
            console.log(error)
          })

        this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
        let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id).filter((item) => item !== stepIngredient.catalog_id)
        this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
      }
      this.stepItems.push(stepIngredient)
    },

    onFileChange(e) {
      const file = e.target.files[0];
      if (file.size > 1000000) {
        alert('Image may not be greater than 1 Mb.')
      } else {
        this.step.image = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },
    closeModal() {
      this.ingredientEditModalIsVisible = this.showErrorsInModal = false
    },
    updateRecipe() {
      this.processing = true;
      this.step.image === this.imagePreview ? delete this.step['image'] : ''
      this.processing = true
      this.form.put(route('admin.kitchen.step.update', this.step.id),this.step)
        .then((response) => {
          if(response.status === 200) {
            this.$toast.success('Step Updated Successfully.')
            this.step['ingredients'] = response.step.ingredients
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false;
        })
    },
    previousStep() {
      this.processing = true;
      --this.currentStep;
      this.form.get(route('admin.kitchen.step.show', this.stepId))
        .then((response) => {
          this.step = response.step;
          --this.stepId
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false;
        })
    },
    findUOM(ingredient) {
      this.unitOfMeasures = this.stepItems.find((item) => item.name === ingredient).variations_data.map(function (unitOfMeasure) {
        return unitOfMeasure.item_variation_data.name ;
      });
    },
    goBack() {
      this.$emit('goBack')
    },
    updateIngredient(ingredient) {
      let ingredients = this.step.ingredients.map((ingredient) => ingredient.name);
      if (ingredients.includes(ingredient.name) && this.step.ingredients[this.ingredientId].name !== ingredient.name) {
        this.step.ingredients[ingredients.indexOf(ingredient.name)].quantity = parseInt(ingredient.quantity) + parseInt(this.step.ingredients[ingredients.indexOf(ingredient.name)].quantity) ;
      }else{
        this.step.ingredients[this.ingredientId] = ingredient
      }
      this.ingredientEditModalIsVisible = false
    },
    findSku(variationDataId) {
      if (variationDataId !== null && variationDataId !== undefined) {
        this.ingredient.variation_data_id = variationDataId
        this.recipeYieldSku = this.recipeUnitOfMeasures.find((item) => item.id === variationDataId).sku
      }
    },
    updateIngredientDetails(ingredient) {
      this.step.ingredients[this.ingredientId] = ingredient
      this.stepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
      let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id)
      this.stepItems = this.stepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
    },
    searchBySku() {
      if (!this.skuValue.trim()) {
        this.$toast.error('Please enter some Sku Value !');
        return;
      }
      let selectedRecipeCatalog, selectedItemVariation;
      let skuFound = this.stepItems.find((recipeYield) => {
        return Object.entries(recipeYield).find(([itemKey, itemValue]) => {
          if (itemKey === 'variations_data') {
            return Object.entries(itemValue).find(([variationDataKey, variationDataValue]) => {
              return Object.entries(variationDataValue).find(([itemVariationKey, itemVariationValue]) => {
                if (itemVariationKey === 'item_variation_data') {
                  if (itemVariationValue.hasOwnProperty('sku')) {
                    if (itemVariationValue.sku === this.skuValue) {
                      selectedRecipeCatalog = recipeYield
                      selectedItemVariation = variationDataValue.id
                      return true;
                    }
                  }
                }
              })
            })
          }
        })
      })
      if (!skuFound) {
        //   clearing the recipe yield if not found in sku
        delete this.ingredient.catalog
        this.$set(this.ingredient, 'catalog_id', null)
        this.$set(this.ingredient, 'variation_data_id', null)
        this.$toast.error('No Sku found !');
      } else {
        this.$set(this.ingredient, 'catalog', selectedRecipeCatalog)
        this.$set(this.ingredient, 'catalog_id', selectedRecipeCatalog.id)
        this.$set(this.ingredient, 'variation_data_id', selectedItemVariation)
        this.findSku(selectedItemVariation)
      }
    },
    changeSelectBy() {
      this.catalogSelectBy = 'name'
    },
    unselectIngredient() {
      this.ingredient.variation_data_id = null
      this.ingredient.catalog_id = null
      this.ingredient.quantity = null
    },
    changeIngredientOrder() {
      this.$http.put(route('admin.kitchen.ingredient.order-change', this.recipe.id) ,{
          'ingredients' : this.step.ingredients
      })
      .then((response) => {
          console.log(response)
      })
      .catch((error) => {
          console.log(error)
      });
    }
  },
  created() {
    this.ingredient.step_id = this.currentStep;
    this.step = JSON.parse(JSON.stringify(this.initial));
    delete this.step.created_at
    delete this.step.updated_at
    this.imagePreview = this.step.image;
    this.initialStepItems = this.items.filter((ingredient) => ingredient.id !== parseInt(this.recipe.catalog_id))
    let ingredientsToRemove = this.step.ingredients.map((ingredient) => ingredient.catalog_id)
    this.stepItems = this.initialStepItems.filter((ingredient) => !ingredientsToRemove.includes(ingredient.id))
  },
  computed: {
    itemCategory: function () {
      return this.items.find((item) => item.id === this.ingredient.catalog_id).category.category;
    },
    itemPrice: function () {
      let variationsData = this.items.find((item) => item.id === this.ingredient.catalog_id).variations_data.find((uom) => uom.id === this.ingredient.variation_data_id)
      let itemYieldPrice = (variationsData !== undefined && variationsData.item_variation_data.price_money) ? (variationsData.item_variation_data.price_money['amount']/100.00) * this.ingredient.quantity : ''
      return itemYieldPrice ? itemYieldPrice.toFixed(2) : itemYieldPrice
    },
    recipeUnitOfMeasures: function (){
      let unitOfMeasures;
      if (this.ingredient.catalog_id !== null) {
        unitOfMeasures = this.stepItems.find((stepItem) => stepItem.id === this.ingredient.catalog_id)?.variations_data.map(function (unitOfMeasure) {

          return {
            id: unitOfMeasure.id,
            value: unitOfMeasure.item_variation_data.name + ' - ' + ((unitOfMeasure.item_variation_data.price_money) ? (unitOfMeasure.item_variation_data.price_money.amount/100.00) : 0),
            sku: unitOfMeasure.item_variation_data.sku,
          }
        });
      }
      // this.ingredient.variation_data_id = this.catalogSelectBy === 'sku' ? this.ingredient.variation_data_id : '';
      this.ingredient.quantity = '';
      return unitOfMeasures;
    },
    stepItemsWithCategory: function () {
      return this.stepItems.map(stepItem => {
        if (stepItem.name) {
          stepItem.nameWithCategory = `${stepItem.name} (${stepItem.category.category})`
          return stepItem
        }
      })
      .filter(stepItemsFiltered => {
        return stepItemsFiltered !== undefined
      })
    }
  },
  watch: {
    step: {
      handler: function (newValue) {
        (newValue.timer === 0) ? this.step.timer_in_minutes = null : ''
      },
      deep: true,
    },
    ingredient: {
      handler: function (newValue) {
        if ('catalog' in newValue) {
          if (newValue.catalog !== null) {
            this.ingredient.catalog_id = newValue.catalog.id;
          }
        }
      },
      deep: true,
    },
    catalogSelectBy: {
      handler: function () {
        this.skuValue = ''
      }
    }

  }
}
</script>

<style scoped>
.add-ingredient-wrapper {
  background: #F8FBFF;
  padding: 2rem;
}
.step-title {
  padding-left: 7.5px;
}
.ingredient-image {
  border-radius: 50% !important;
  box-sizing: border-box;
  height: 60px;
  width: 60px;
}
.ingredient-table th {
  background: #F8F8F8;
  border: 1px solid #D9D9D9;
}
.ingredient-table tr th:nth-child(2) {
  width: 40%;
}
.ingredient-table tr th:nth-child(6), .ingredient-table tr th:nth-child(3){
  width: 15%;
}
.ingredient-table tr th:nth-child(1), .ingredient-table tr th:nth-child(4),  .ingredient-table tr th:nth-child(5)  {
  width: 10%;
}
.scale-checkbox label {
  margin-top: 3px;
}
.dynamic-label{
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
  background-color: #6c757d;
}

.dynamic-label label {
  line-height: 1.2;
}


</style>
