<template>
  <div class="modal modal-center fade kitchen-category-add-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display: none'"
       id="categoryAddModal"
       ref="categoryAddModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content w-100 h-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Add Employee</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>


        </div>
        <div class="modal-body p-2">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="addEmployee()" ref="addEmployee">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter the name"
                      class="form-control"
                      :class="{ 'is-invalid' : form.$errors.has('name')}"
                      v-model="name"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('name')"
                    >
                      {{ form.$errors.first('name') }}
                    </div>
                  </div>

                  <div class="form-group div-pin">
                    <label>Pin</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Pin"
                      :class="{ 'is-invalid' : form.$errors.has('pin')}"
                      v-model="pin"
                    >
                    <input type="button" class="btn btn--outline-primary btn-pin mr-3" @click="generatePin()" value="Generate">
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('pin')"
                    >
                      {{ form.$errors.first('pin') }}
                    </div>
                  </div>

                  <div class="form-group d-flex">
                    <input
                      type="checkbox"
                      v-model="is_admin"
                    >
                    <label for="is_admin" class="permission-checkbox"> Give App Admin Permission</label>
                  </div>

                  <div class="form-group d-flex">
                    <input
                      type="checkbox"
                      v-model="can_add_to_worklist"
                    >
                    <label for="can_add_to_worklist" class="permission-checkbox"> Give Move To Worklist Permission</label>
                  </div>

                  <div class="form-group">
                    <input type="button" class="btn btn-secondary" @click="closeModal" value="Cancel">
                    <input type="submit" class="btn btn-primary ml-3" value="Save">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "laravel-form-validation";

export default {
  name: "EmployeeAddModal.vue",
  props: {
    showModal: {
      type: Boolean,
    },
    authUser: {
      type: Object,
    }
  },
  data() {
    return {
      name: '',
      pin:'',
      is_admin: 0,
      can_add_to_worklist: 0,
      form: new Form()

    }
  },

  methods: {
    closeModal() {
      this.form = new Form();
      this.$emit('closeEmployeeAddModal');
      this.name = this.pin = '';
      this.is_admin = 0;
      this.can_add_to_worklist = 0;
    },
    generatePin() {
      this.processing = true;
      this.$http.get(route('admin.kitchen.employee.generate-pin'))
        .then((response) => {
          if(response.status === 200) {
            this.$toast.success('Pin Generated Successfully');
            this.pin = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('Something went wrong')
        })
        .finally(() => {
          console.log("test");
        })
    },
    addEmployee() {
      this.processing = true;
      this.form.post(route('admin.kitchen.employee.store'), {
        'name': this.name,
        'pin': this.pin,
        'is_admin': this.is_admin,
        'can_add_to_worklist': this.can_add_to_worklist
      })
        .then((response) => {
          if(response.status === 202) {
            this.$toast.error(response.message);
          }
          else {
            this.$toast.success('Employee Added Successfully');
            this.name= '';
            this.pin= '';
            this.is_admin= 0;
            this.can_add_to_worklist= 0;
            this.$emit('closeEmployeeAddModal', response);
          }
        })
        .catch((error) => {
          // this.$toast.error('Something went wrong')
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style>

.div-pin {
  position: relative;
}

.btn-pin {
  position: absolute;
  top: 30px;
  right: 0;
  color: #0000FFFF;
  font-size: 13px;
}
.permission-checkbox {
  margin: -2px 10px;
}
</style>
