<template>
  <form @submit.prevent="submit" enctype="multipart/form-data">
    <div class="card-body">
      <div class="form-group">
        <label for="type_id">Label Type</label>
        <input
          id="type_id"
          name="type_id"
          type="text"
          class="form-control"
          :value="label.type.type"
          :disabled="true"
        >
      </div>

      <div class="form-group">
        <label for="name">Label Format Name</label>
        <input
          id="name"
          name="name"
          type="text"
          class="form-control"
          :value="label.name"
          :disabled="true"
        >
      </div>

      <span v-if="authUser.is_super_admin">
        <div class="form-group">
        <label for="name">Description Count</label>
        <input
          id="description_count"
          name="description_count"
          type="text"
          class="form-control"
          v-invalid="form.$errors"
          placeholder="Enter Description Count"
          v-model="label.description_count"
          required
        >
        <field-error :bag="form.$errors" field="description_count"/>
      </div>

        <div class="form-group">
          <label for="category_id">Label Format Type</label>
          <select class="form-control" name="category_id" id="category_id" v-model="label.category_id" required>
            <option v-for="label_category in labelCategories" :value="label_category.id" :key="label_category.id"> {{ label_category['name'] }} </option>
          </select>
        </div>

        <div class="form-group">
          <label for="zpl_data">ZPL Data</label>
          <textarea class="form-control" name="zpl_data" id="zpl_data" rows="6" v-model="label.zpl_data" v-invalid="form.$errors">
        </textarea>
          <field-error :bag="form.$errors" field="zpl_data"/>
        </div>

        <div class="form-group">
          <label for="image">Label Image</label>
          <b-form-file
            id="image"
            name="image"
            type="file"
            :state="form.$errors.has('image') ? false : null"
            v-model="label.image"
            placeholder="Choose File"
            accept="image/*"
          />
          <field-error :bag="form.$errors" field="image"/>
          <img :src="label.image_url"
               alt=""
               id="preview"
               class="rounded-circle"
               style="width:80px;height:80px;margin-top:10px">
        </div>

        <div class="form-group edit-multiselect-wrapper">
          <label for="merchants">Assign Merchants</label>
          <multiselect
            id="merchants"
            name="merchants"
            label="display_name"
            track-by="id"
            v-model="label.merchants"
            :options="merchantsOptions"
            :multiple="true"
            :taggable="false"
            :searchable="true"
            :close-on-select="false"
            placeholder="Select Merchants"

          />
          <field-error :bag="form.$errors" field="merchants" class="d-block"/>
        </div>
      </span>

      <div class="form-group">
        <label for="status">Status</label>
        <select
          name="status"
          id="status"
          class="form-control"
          v-model="label.status"
          v-invalid="form.$errors"
        >
          <option :value="1">Active</option>
          <option :value="0">InActive</option>
        </select>
        <field-error :bag="form.$errors" field="status"/>
      </div>

      <div class="form-group">
        <button type="submit" :disabled="form.$pending" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</template>

<script>

import Form from 'laravel-form-validation';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'EditCustomLabelFormat',
  props: {
    initial: {
      type: Object,
    },
    merchants: {
      type: Array,
    },
    authUser: {
      type: Object,
    },
    labelCategories : {
      type: Array,
    },
  },
  data() {
    return {
      label: {
        merchants: [],
        zpl_data: null,
        category_id : null,
        label_format_code : [

        ],
      },
      form: new Form(),
    }
  },
  created() {
    this.seedForm()
  },
  methods: {
    seedForm(){
      console.log(this.label);
      this.label = Object.assign({},this.initial, { merchants: [], status: this.initial.pivot.status  });
      this.label.zpl_data = this.label.label_format_code.zpl_data;
      this.label.merchants = this.label.admins.map(item => {
        item.display_name = item.profile.business_name;
        return item;
      });
      delete this.label.admins;
    },
    submit() {
      this.form.put(route('admin.custom-label-format.update', this.initial.id), this.label)
        .then((response) => {
          if (response.status === 202) {
            this.$toast.error(response.message);
          } else {
            window.location = route('admin.custom-label-format.index');
            this.$toast.success('Label Format Updated successfully');
          }
        })
    },
    onChange(event) {
      let files = event.target.files;

      // Drag and Drop support
      if (!files.length) {
        files = event.dataTransfer.files
      }

      if (files.length) {
        const reader = new FileReader();

        reader.onload = e => {
          this.imageUrl = e.target.result;
        }

        this.$emit('input', files[0]);
        this.image = files[0]
        reader.readAsDataURL(files[0]);
      } else {
        this.$emit('input', null);
      }
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    merchantsOptions() {
      return this.merchants.map(item => {
        item.display_name = item.profile?.business_name;
        return item;
      });
    }
  }
}
</script>
