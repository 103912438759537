<template>
  <div class="modal modal-center fade kitchen-category-edit-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'padding-right: 14px; display: block;' : 'display :none; '"
       id="categoryEditModal"
       ref="categoryEditModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content w-100 h-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Edit Employee</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="text-dark">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateEmployee()">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter the name"
                      class="form-control"
                      :class="{ 'is-invalid' : form.$errors.has('name')}"
                      v-model="employee.name"
                    >
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('name')"
                    >
                      {{ form.$errors.first('name') }}
                    </div>
                  </div>

                  <div class="form-group div-pin">
                    <label>Pin</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Pin"
                      :class="{ 'is-invalid' : form.$errors.has('pin')}"
                      v-model="employee.pin"
                    >
                    <input type="button" class="btn btn--outline-primary btn-pin mr-3" @click="generatePin" value="Generate">
                    <div
                      class="invalid-feedback mt-1"
                      v-show="form.$errors.has('pin')"
                    >
                      {{ form.$errors.first('pin') }}
                    </div>
                  </div>

                  <div class="form-group d-flex">
                    <input
                      type="checkbox"
                      v-model="employee.is_admin"
                      :checked="employee.is_admin"
                    >
                    <label for="is_admin" class="permission-checkbox"> Give App Admin Permission</label>
                  </div>

                  <div class="form-group d-flex">
                    <input
                      type="checkbox"
                      v-model="employee.can_add_to_worklist"
                      :checked="employee.can_add_to_worklist"
                    >
                    <label for="can_add_to_worklist" class="permission-checkbox"> Give Move To Worklist Permission</label>
                  </div>

                  <div class="form-group">
                    <input type="button" class="btn btn-secondary" @click="closeModal" value="Cancel">
                    <input type="submit" class="btn btn-primary ml-3" value="Update">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "laravel-form-validation";

export default {
  name: "EmployeeEditModal.vue",

  props: {
    showModal: {
      type: Boolean
    },
    authUser: {
      type: Object
    },
    initial: {
      type: Object
    },
    pin:{}
  },

  data() {
    return {
      employee: {},
      form: new Form()
    }
  },
  watch: {
    initial(newValue) {
      this.employee.name = newValue.name;
      this.employee.pin = newValue.pin;
      this.employee.is_admin = newValue.is_admin;
      this.employee.can_add_to_worklist = newValue.can_add_to_worklist;
    },
    pin(newValue) {
      this.employee.pin = newValue;
    }
  },
  methods: {
    closeModal() {
      this.form = new Form();
      this.$emit('closeEmployeeEditModal');
    },
    generatePin() {
      this.form = new Form();
      this.$emit('generatePin');
      this.employee.pin = this.pin
    },
    updateEmployee() {
      this.processing = true;
      this.form.put(route('admin.kitchen.employee.update', this.initial.id),  this.employee)
      .then((response) => {
          this.$toast.success('Employee updated successfully');
          this.$emit('closeEmployeeEditModal', response, this.initial._index);
      })
      .catch((error) => {
        // this.$toast.error('Something went wrong')
      })
      .finally(() => {
        this.procesing = false
      })
    }
  }
}
</script>

<style>

.div-pin {
  position: relative;
}

.btn-pin {
  position: absolute;
  top: 30px;
  right: 0;
  color: #0000FFFF;
  font-size: 13px;
}
.permission-checkbox {
  margin: -2px 10px;
}
</style>
