<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <div class="row align-items-center">
                  <div class="col-6">
                      <h3 class="card-title">Admin List</h3>
                  </div>
                  <div class="col-6 text-right">
                      <a :href="route('admin.admins.create')" class="btn btn-primary btn-small">Add Admin</a>
                  </div>
              </div>
            </div>
            <div class="card-body">
                 <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2" v-model="params.per_page">
                        <option disabled>Per page</option>
                        <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                   <b-dropdown variant="light" left>
                     <template v-slot:button-content>
                        <span>
                            Search by Admin
                        </span>
                       <!--                      <span v-else>-->
                       <!--                          {{ params.category_ids.length }} Selected-->
                       <!--                      </span>-->
                     </template>
                     <div class="subscription-list">
                       <div v-for="(subscription , index) in subscriptionTypes" :key="index" class="dropdown-item">
                         <div class="form-group">
                           <input type="checkbox" class="form-check-input"
                                  v-model="params.subscription_ids" :value="index"
                                  :id="`subscription-${index}`">
                           <label class="form-check-label mt-1 ml-1" style="font-size: 14px"
                                  :for="`subscription-${index}`">{{subscription}}</label>
                         </div>
                       </div>
                     </div>
                   </b-dropdown>

                    <input type="text" class="form-control mb-2 mb-sm-0 mx-sm-2" placeholder="Search by user" name="search"
                            v-model.trim="params.search">
                    <button type="submit" class="btn btn-primary mb-0 mb-sm-0 mr-sm-2" :disabled="processing">
                        <i class="fa fa-search fa-fw"/>
                    </button>
                    <div>
                        <i class="ml-2 fas fa-spin fa-sync-alt text-muted" v-show="processing" aria-label="Loading"/>
                    </div>
                </form>
                <div class="table-wrapper table-responsive">
                  <table id="table" class="table table-bordered table-striped table-hover mb-2" >
                    <thead>
                        <tr>
                            <th v-for="(column, key, index) in columns" :key="index">
                                <a href="#" @click="sort(key)"> {{column}} </a>
                            </th>
                            <th>
                                <a href="#">Action</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="sortedAdmins.length > 0">
                        <tr v-for="(admin, index) in sortedAdmins" :key="index">
                            <td>{{ admin.email }}</td>
                            <td>{{ admin.name }}</td>
                        <td>
                          {{ admin.subscription_type ?
                            ((admin.subscription_type.includes("1")) && (admin.subscription_type.includes("2"))) ?
                              "DELILABEL, KITCHEN HAND" : admin.subscription_type.includes("1") ? "DELILABEL" : "KITCHEN HAND" : "NULL"
                          }}
                        </td>
                          <td>{{ formatDateTime(admin.created_at) }}</td>
                            <td>{{ formatDateTime(admin.updated_at) }}</td>
                            <td>
                              <div class="btn-group btn-group-sm">
                                <a title="Edit" :href="route('admin.admins.edit', admin.id)" class="btn btn-primary"><i class="fas fa-edit"></i></a>
                                <a title="Delete" href="#" @click="deleteAdmin(admin)" class="btn btn-danger" v-if="authUser.is_merchant"><i class="fas fa-trash"></i></a>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot v-else>
                        <td colspan="5">
                          <div class="text-center">
                              <strong>No Results Found</strong>
                          </div>
                        </td>
                    </tfoot>
                  </table>
              </div>
              <div class="text-center">
                    <pagination class="mb-0"
                                @pagination-change-page="pageNavigated"
                                :data="response"
                                :show-disabled="true"
                                :limit="2"/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';
import {scrollToTop} from "@/js/scrollToTop.js";

export default {
  name: "AdminIndex",
  components: {
      Pagination,
  },
  props: {
    initial: {
      type: Object,
    },
    authUser: {
        type: Object,
    },
    subscription_types: {
      type: Object,
    }
  },
  data() {
    return {
      processing: false,
      sortKey: "name",
      sortOrder: "asc",
      params: {
          page: 1,
          search: null,
          per_page: 15,
          subscription_ids: [],
          sortBy: 'name',
          orderBy: 'asc',
      },
      response: {},
      subscriptionTypes: {
        '1' : 'DeliLabel',
        '2' : 'KitchenHand',
      },
      columns: {'email':'Email', 'name':'Name', 'admin_type' : 'Admin Type' , 'created_at':'Created At', 'updated_at':'Updated At'},
    }
  },
  created() {
      this.response = this.initial;
      this.response.data = this.initial.data;
  },
  methods: {
    sort(sortKey) {
      if (sortKey === this.sortKey) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      }
      this.sortKey = sortKey;
    },
    pageNavigated(page = 1) {
        this.params.page = page;
        this.fetch();
    },
    fetch() {
        this.processing = true;
        scrollToTop();
        this.$http.get(route('admin.admins.index'), {
            params: this.params,
        })
            .then((response) => {
              if(this.params.search == null) {
                this.response.data = this.initial.data;
              }
              if(this.params.subscription_ids.length > 0) {
                this.response.data = Object.values(response.data.data);
              }
              else{
                this.response.data = response.data.data;
              }
            })
            .catch((error) => {
                this.$toast.error('Something went wrong');
            })
            .finally(() =>{
              this.processing = false;
            })
    },
    async deleteAdmin(admin) {
      const confirm = await this.$bvModal.msgBoxConfirm('Please be careful, Admin user will be deleted permanently from admin.',{
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }

      this.$http.delete(route('admin.admins.destroy', [admin.id]))
      .then(() => {
        this.$toast.success('Admin has been deleted')
        setTimeout(function() { location.reload() }, 1000)
      })
      .catch(error => {
        console.error(console.error)
      })

    },
    formatDateTime (dateTime) {
        return dayjs.utc(dateTime).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
    },

  },

  computed: {
    admins: function () {
      return this.response.data;
    },
    sortedAdmins: function () {
      return this.admins.sort((a, b) => {
        let modifier = 1;
        if (this.sortOrder === "desc") modifier = -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
