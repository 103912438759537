<template>
<div class="text-center subscription-card">
    <h3 class="text-bold text-secondary">Please select a subscription below to continue.</h3>
      <ul class="nav nav-pills plan-toggle d-inline-flex justify-content-center align-items-center">
        <li>
          <a href="#" class="active" data-toggle="pill" @click="showMonthlyPlans()">Monthly</a>
        </li>
        <li><a href="#" data-toggle="pill" @click="showYearlyPlans()">Yearly</a></li>
      </ul>
    <div class="row mt-4 subscription-card-wrapper justify-content-center">
        <div id="monthly-plan" class="col-lg-6" v-if="showMonthlyPlanDiv" v-for="subscription in monthlySubscriptions" :key="subscription.id">
          <div class="card" v-if="subscription.cycle === 'month'"
               v-b-tooltip.hover="{title: selectedSubscription.includes(subscription.id) ?
               'Yearly subscription already selected.' : (disableSubscriptions.includes(subscription.id) ? 'You already have this plan active.' : null), variant: 'primary'}">
            <label :for="subscription.id" class="radio-card">
            <input type="checkbox"
                   name="radio-card"
                   :id="subscription.id"
                   @change="addSubscription($event, subscription)"
                   class="check-icon"
                   :value="subscription.id"
                   v-model="buySubscriptionId"
                   :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id) || selectedSubscription.includes(subscription.id)"
            >
            <!-- <span class="check-icon"></span> -->
            <div class="card-header">
              <div class="brand">
                <span>{{ subscription.name }}</span>
              </div>
              <div class="tab-content">
                <div id="monthly-plan">
                  <h3>${{ subscription.price }}</h3>
                  <hr>
                  <h4>{{ subscription.name }}</h4>
                </div>
              </div>

            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li>Unlimited admin accounts.</li>
                <li>Unlimited user accounts.</li>
                <li v-if="subscription.name === 'KitchenHand Monthly'">Unlimited Recipes.</li>
                <li>Unlimited Square items SKUs.</li>
                <li>Multiple Square locations.</li>
              </ul>
              <!-- <button
                class="btn btn-outline-primary"
                :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id)"
                @click="subscribe(subscription)"
              >
                <i
                  class="fas"
                  :class="processing && !subscription.price ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
                /> Subscribe
              </button> -->
            </div>
          </label>
          </div>
        </div>

      <div id="yearly-plan" class="col-lg-6" v-if="showYearlyPlanDiv" v-for="subscription in yearlySubscriptions" :key="subscription.id">
        <div class="card" v-if="subscription.cycle === 'year'"
             v-b-tooltip.hover="{title: selectedSubscription.includes(subscription.id) ?
               'Monthly subscription already selected.' : (disableSubscriptions.includes(subscription.id) ? 'You already have this plan active.' : null), variant: 'primary'}">
          <label :for="subscription.id" class="radio-card">
          <input type="checkbox"
                 name="radio-card"
                 @change="addSubscription($event, subscription)"
                 :id="subscription.id"
                 class="check-icon"
                 :value="subscription.id"
                 v-model="buySubscriptionId"
                 :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id) || selectedSubscription.includes(subscription.id)"
          />
          <div class="card-header">
            <div class="brand">
              <span>{{ subscription.name }}</span>
            </div>
            <div class="tab-content">
              <div id="monthly-plan">
                <h3>${{ subscription.price }}</h3>
                <hr>
                <h4>{{ subscription.name }}</h4>
              </div>
            </div>

          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>Unlimited admin accounts.</li>
              <li>Unlimited user accounts.</li>
              <li v-if="subscription.name === 'KitchenHand Yearly'">Unlimited Recipes.</li>
              <li>Unlimited Square items SKUs.</li>
              <li>Multiple Square locations.</li>
            </ul>
            <!-- <button
              class="btn btn-outline-primary"
              :disabled="(user.trial_ends_at && !subscription.price) || processing || disableSubscriptions.includes(subscription.id)"
              @click="subscribe(subscription, disableSubscriptions)"
            >
              <i
                class="fas"
                :class="processing && !subscription.price ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
              /> Subscribe
            </button> -->
          </div>
          </label>
        </div>
      </div>
    </div>
    <div class="subscription-btn-wrapper my-3" >
      <div class="coupon-input-wrapper date-filter">
        <input type="text"
               v-if="showTrial()"
               placeholder="Enter Coupon, if available!"
               name="coupon_code"
               v-model="couponCode">
      </div>

      <button class="btn btn-outline-primary"
              @click="subscribe(buySubscription, disableSubscriptions, couponCode)"
              :disabled="(processing || buySubscription.length === 0)"
      >
        <i class="fas" :class="processing && !subscription.price ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"/> Subscribe
      </button>
    </div>
    <button class="btn btn-primary trial-btn"
          v-if="showTrial()"
          :disabled="trialProcessing"
          @click="startTrial()">
    Start 30 day free trial
  </button>
</div>
</template>

<script>
import {VBTooltip} from 'bootstrap-vue';
// const checkboxes = document.querySelectorAll('input[type="checkbox"]');
// checkboxes.forEach((checkbox) => {
//   checkbox.addEventListener('change', () => {
//     const cardWrapper = checkbox.closest('.radio-card');
//     if (checkbox.checked) {
//       cardWrapper.classList.add('checked');
//     } else {
//       cardWrapper.classList.remove('checked');
//     }
//   });
// });
export default {
    name: 'SubscriptionCard',
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        initial: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        processing : {
            type: Boolean,
            required: true,
        },
        userSubscriptions: {
            type: Array,
            required:true
        },
        stripeProducts: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
          trialProcessing: false,
          showMonthlyPlanDiv: true,
          showYearlyPlanDiv: false,
          ClassAdds: false,
          disableSubscriptions: [],
          buySubscription: [],
          selectedSubscription: [],
          buySubscriptionId: [],
          couponCode: null
        }
    },
    created() {
      this.findDisableSubscription()
    },
    methods: {
        subscribe(subscription, disableSubscriptions, couponCode) {
            this.$emit('subscribe', subscription, couponCode);
        },
        findDisableSubscription() {
          for( let subscription of this.userSubscriptions) {
            for( let [key, value] of Object.entries(this.stripeProducts)) {
               if(value.includes(subscription)) {
                 this.disableSubscriptions.push(...value);
               }
            }
          }
        },
      startTrial() {
        this.trialProcessing = true;
        this.$http.post(route('admin.subscriptions.subscribe.trial'))
          .then(response => {
            this.$toast.success('Congratulations! your trial subscription has been started.');
            window.location = route('admin.');
          })
          .catch(() => {
            this.$toast.error('You cannot get trial subscription now. Please contact admin')
          })
          .finally(() => {
            this.trialProcessing = false;
          })
      },

      showMonthlyPlans() {
        this.showMonthlyPlanDiv = true
        this.showYearlyPlanDiv = false
      },

      showYearlyPlans() {
        this.showMonthlyPlanDiv = false
        this.showYearlyPlanDiv = true
      },

      showTrial() {
          return !this.disableSubscriptions.length && !this.user.trial_ends_at
      },
      addSubscription(event, subscription) {
        if (event.target.checked) {
          this.buySubscription.push(subscription)
          for( let [key, value] of Object.entries(this.stripeProducts)) {
            for(let [key, id] of Object.entries((value))) {
              if(id === subscription.id) {
                this.selectedSubscription.push(...value);
                this.selectedSubscription = this.selectedSubscription.filter(item => item !== id)
              }
            }
          }
        }
        else {
          this.buySubscription.forEach((data, index) => {
            if (data.id === subscription.id) {
              this.buySubscription.splice(index, 1);
            }
          });
          for( let [key, value] of Object.entries(this.stripeProducts)) {
            if(value.includes(subscription.id)) {
              value.forEach(element => {
                this.selectedSubscription = this.selectedSubscription.filter(item=> item !== element);
              });
            }
          }
        }
      },

      isSubscriptionChecked(id) {
        this.buySubscription.forEach((data) => {
          console.log(data, id, data.id === id);
          if(data.id === id) {
            return true
          }
        })
        return false;
      }
    },
    computed: {
        monthlySubscriptions() {
            return this.initial.filter( function (subscription){
              return subscription.cycle === 'month';
            });
        },
        yearlySubscriptions() {
          return this.initial.filter( function (subscription) {
            return subscription.cycle === 'year';
          });
        }
    }
}
</script>

<style scoped>
.plan-toggle a {
  display: block;
}
.subscription-card-wrapper > div {
  margin: 15px auto;
}
.trial-btn {
  font-size: 14px;
  display: inline-block;
  min-width: 350px;
  background: #0E54EA;
  font-weight: 700;
  border: none;
  transition: all .3s ease-in-out;
}
.trial-btn:hover {
  background: #007bff;
  border: none;
}
/* Card multi-select CSS Starts here */
label.radio-card {
  cursor: pointer;
}
.check-icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	border: solid 2px #e3e3e3;
	border-radius: 50%;
	position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  transition: all .2s linear;
}
.check-icon:before {
	content: '';
	position: absolute;
	inset: 0;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: center center;
	opacity: 1;
}
.check-icon[type='checkbox'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
.check-icon.checked, .check-icon:checked  {
	background: #3057d5;
	border-color: #3057d5;
	transform: scale(1.2);
}
.check-icon.checked:before, .check-icon:checked:before {
  transform: scale(1);
  opacity: 1;
}
.check-icon.checked:focus, .check-icon:checked:focus {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}
.coupon-input-wrapper {
  max-width: 170px;
  margin:10px auto;
  border: #0a1520;
}
</style>
