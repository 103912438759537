<template>
    <div class="card mt-2">
        <div class="card-header">
            <h3 class="card-title">Please Choose a subscription</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <form @submit.prevent="submit">
                        <div
                            class="custom-control custom-radio custom-control-inline mb-3"
                            v-for="(subscription, index) in subscriptions"
                            :key="subscription.id"
                        >
                            <input
                                class="custom-control-input"
                                type="radio"
                                name="subscription"
                                @click="showPaymentForm"
                                :id="'subscription_'+ subscription.id"
                                :value="subscription"
                                :class="{ 'is-invalid' : form.$errors.has('subscription.id' || 'subscription.price_id')}"
                                v-model="selectedSubscription"
                            >
                            <label class="custom-control-label pt-1" :for="'subscription_'+ subscription.id">
                                {{ subscription.name }} - {{ subscription.price | currency}}
                            </label>
                        </div>
                        <div
                            class="invalid-feedback d-block"
                            v-show="form.$errors.has('subscription.id' || 'subscription.price_id')"
                        >
                            {{ form.$errors.first('subscription.id' || 'subscription.price_id') }}
                        </div>
                        <div class="form-check mb-2" v-if="user.is_stripe_customer">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="pay-using-default-card"
                                v-model="isDefaultPaymentMethod"
                                :value="isDefaultPaymentMethod"
                                @click="switchPaymentMethod">
                            <label
                                class="form-check-label mt-1"
                                for="pay-using-default-card"
                            >
                                <strong>Pay through default payment method</strong>
                            </label>
                        </div>
                        <div :class="{'d-none' : !isSubscriptionSelected}">
                            <h5>Card billing address details</h5>
                            <div class="form-row mb-2">
                                <div class="col-md-6">
                                    <div class="input-group required">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-map-marked-alt"/>
                                    </span>
                                        </div>
                                        <input
                                            id="input_address_line_1"
                                            name="address_line_1"
                                            placeholder="Address line 1"
                                            type="text"
                                            maxlength="255"
                                            required
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.line1')}"
                                            v-model.trim="card.address.line1"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.line1')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.line1') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-map-marked-alt"/>
                                    </span>
                                        </div>
                                        <input
                                            id="input_address_line_2"
                                            name="address_line_2"
                                            placeholder="Address line 2"
                                            type="text"
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.line2')}"
                                            v-model.trim="card.address.line2"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.line2')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.line2') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row mb-2">
                                <div class="col-md-6">
                                    <div class="input-group required">
                                        <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-map-marked-alt"/>
                                    </span>
                                        </div>
                                        <input
                                            id="input_city"
                                            name="city"
                                            placeholder="City"
                                            type="text"
                                            maxlength="50"
                                            required
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.city')}"
                                            v-model.trim="card.address.city"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.city')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.city') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group required">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-map-marked-alt"/>
                                        </span>
                                        </div>
                                        <input
                                            id="input_zip-code"
                                            name="zip_code"
                                            placeholder="Zip code"
                                            type="text"
                                            maxlength="10"
                                            required
                                            class="form-control"
                                            :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.address.postal_code')}"
                                            v-model.number="card.address.postal_code"
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-show="form.$errors.has('payment_method.billing_details.address.postal_code')"
                                        >
                                            {{ form.$errors.first('payment_method.billing_details.address.postal_code') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section :class="{'d-none' : !isDefaultPaymentMethod}">
                                <h5>Default payment method</h5>
                                <credit-card
                                    v-if="isDefaultPaymentMethod"
                                    :payment-method="defaultPaymentMethod"
                                ></credit-card>
                            </section>
                            <section :class="{'d-none' : isDefaultPaymentMethod}">
                                <h5>Card details</h5>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="input-group required">
                                            <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="fas fa-user"/>
                                            </span>
                                            </div>
                                            <input
                                                id="input_card-holder-name"
                                                name="card-holder"
                                                placeholder="Card holder name"
                                                type="text"
                                                maxlength="255"
                                                :required="!isDefaultPaymentMethod"
                                                class="form-control"
                                                :class="{ 'is-invalid' : form.$errors.has('payment_method.billing_details.name')}"
                                                v-model.trim="card.holder"
                                            >
                                            <div
                                                class="invalid-feedback"
                                                v-show="form.$errors.has('payment_method.billing_details.name')"
                                            >
                                                {{ form.$errors.first('payment_method.billing_details.name') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div ref="cardElement" class="form-control pt-2"/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <button
                                class="btn btn-primary"
                                :disabled="processing"
                            >
                                <i
                                    class="fas"
                                    :class="processing ? 'fa-circle-notch fa-spin' : 'fa-check-circle'"
                                /> Subscribe
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from 'laravel-form-validation';
import loadScript from 'load-script2';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import CreditCard from '@/js/Components/Shared/Components/CreditCard.vue';
import {calculateGrandTotal} from '@/js/Components/Shared/subscriptionGrandTotal.js';
import {grandTotalTemplate} from '@/js/Components/Shared/grandTotalPreviewTemplate.js';
/*global Stripe*/

dayjs.extend(relativeTime);

export default {
    name: 'UpgradeSubscription',
    props: {
        user: {
            type: Object,
            required: true,
        },
        subscriptions: {
            type: Array,
            required: true,
        },
        zipCodes: {
            type: Object,
            required: true,
        }
    },
    components: {
        CreditCard
    },
    data() {
        return {
            processing: false,
            card: {
                holder: this.user.name,
                element: null,
                address: {}
            },
            stripe: null,
            selectedSubscription: {},
            isDefaultPaymentMethod: false,
            defaultPaymentMethod: {},
            isSubscriptionSelected: false,
            isTaxApplicable: false,
            form: new Form(),
        }
    },
    created() {
        this.init();
        this.fetchDefaultPaymentMethod();
    },
    methods: {
        init() {
            this.processing = true;
            loadScript('https://js.stripe.com/v3')
                .then(() => {
                    if (this.stripe) {
                        this.card.element.destroy();
                    }
                    this.stripe = Stripe(window.appConfig.stripeKey);

                    this.card.element = this.stripe.elements().create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#495057',
                            }
                        }
                    });
                    this.card.element.mount(this.$refs.cardElement);
                    this.processing = false;
                })
        },
        fetchDefaultPaymentMethod() {
            this.processing = true;
            this.$http.get(route('admin.default.card'))
                .then((response) => {
                    this.defaultPaymentMethod = response.data;
                })
                .catch(() => {
                    // this.$toast.error("Default card doesn't exist");
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        switchPaymentMethod() {
            this.isDefaultPaymentMethod = ! this.isDefaultPaymentMethod;

            if (this.isDefaultPaymentMethod) {
                let billingAddress = Object.assign({}, this.defaultPaymentMethod.billing_details.address);
                this.card.address = billingAddress;
            } else {
                this.card.address = {};
            }
        },
        async submit() {
            this.processing = true;

            let {isTaxApplicable, taxRate, tax, grandTotal} = calculateGrandTotal(
                this.selectedSubscription.price,
                this.zipCodes,
                this.zipCode,
            );

            this.isTaxApplicable = isTaxApplicable;

            let message = grandTotalTemplate(this, this.selectedSubscription.price, taxRate, tax, grandTotal);

            const confirm = await this.$bvModal.msgBoxConfirm(message, {
                okVariant: 'primary',
                title: 'Please Confirm Your Subscription',
                okTitle: 'Proceed',
            });

            if (!confirm) {
                this.processing = false;
                return;
            }

            if (this.isDefaultPaymentMethod) {
                this.defaultPaymentMethod.billing_details.email = this.user.email;
                this.defaultPaymentMethod.billing_details.phone = this.user.phone;
                this.defaultPaymentMethod.billing_details.address = Object.assign({}, this.card.address);
                this.sendToServer(this.defaultPaymentMethod);

                return;
            }

            const {paymentMethod, error} =  await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card.element,
                billing_details: {
                    name: this.card.holder,
                    email: this.user.email,
                    phone: this.user.phone,
                    address: {
                        line1: this.card.address.line1,
                        line2: this.card.address.line2,
                        city: this.card.address.city,
                        postal_code: this.card.address.postal_code,
                    }
                },
            })

            if (error) {
                this.processing = false;
                this.$toast.error(error.message);
            } else {
                this.sendToServer(paymentMethod);
            }
        },
        sendToServer(paymentMethod) {
            this.form.post(route('admin.subscriptions.subscribe.paid'), {
                payment_method: paymentMethod,
                subscription: this.selectedSubscription,
                isTaxApplicable: this.isTaxRequired
            })
                .then(() => {
                    this.$toast.success('Congratulations! your subscription has been upgraded.');

                    setTimeout(() => {
                        window.location = route('admin.');
                    }, 500);
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        showPaymentForm() {
            this.isSubscriptionSelected = true
        }
    },
    computed: {
        zipCode() {
            return this.card.address.postal_code;
        }
    },
}
</script>
