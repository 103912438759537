<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <h2 class="card-title mb-1 ml-2">Kitchen Employees</h2>
            </div>
            <div class="card-body">
              <div class="row mb-3 input-header">
                <div class="col-lg-8">
                  <form id="search-form" class="form-inline mb-2" @submit.prevent="pageNavigated(1)">
                    <select class="form-control custom-select mb-2 mb-sm-0 mr-sm-2"
                            v-model="params.per_page">
                      <option disabled>Per page</option>
                      <option v-for="n in [15, 30, 50]" :value="n" :key="n">{{ n }}</option>
                    </select>
                    <input type="text"
                           class="form-control col-6 mb-2 mb-sm-0 mr-sm-2"
                           placeholder="Search"
                           name="search"
                           v-model.trim="params.search">
                    <button type="submit"
                            class="btn btn-primary mb-0 mb-sm-0 mr-sm-2"
                            :disabled="processing">
                      <i class="fa fa-search fa-fw"/>
                    </button>
                  </form>
                </div>
                <div class="col-lg-4 button-group">
                  <a class="btn btn-primary btn-small" @click="showEmployeeAddModal()">+ Add New Employee</a>
                </div>
              </div>

              <div class="table-wrapper table-responsive">
                <table id="table" class="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-primary">Name</th>
                    <th class="text-primary">Pin</th>
                    <th class="text-primary">Status</th>
                    <th class="text-primary">Admin Access</th>
                    <th class="text-primary">Move To Worklist Permission</th>
                    <th class="text-primary">Created At</th>
                    <th class="text-primary">Updated At</th>
                    <th class="text-primary">Action</th>
                  </tr>
                </thead>
                <tbody v-if="employees.length">
                  <tr v-for="(employee, index) in employees" :key="index">
                    <td>
                      {{ employee.name }}
                    </td>
                    <td>
                      {{ employee.pin }}
                    </td>
                    <td :class="employee.status ? 'text-success' : 'text-danger'">
                      {{ employee.status ? 'Active' : 'Inactive' }}
                    </td>
                    <td>
                      {{ employee.is_admin ? 'Yes' : 'No' }}
                    </td>
                    <td>
                      {{ employee.can_add_to_worklist ? 'Yes' : 'No' }}
                    </td>
                    <td>
                      {{ formatDateTime(employee.created_at) }}
                    </td>
                    <td>
                      {{ formatDateTime(employee.updated_at) }}
                    </td>
                    <td>
                      <div class="btn-group btn-group-sm">
                        <a title="Edit" class="btn btn-primary" @click="showEmployeeEditModal(employee, index)"><i
                          class="fas fa-edit"/></a>
                          <a title="Delete"
                           href="#"
                           class="btn btn-danger"
                           @click="deleteEmployee(employee, index)"><i class="fas fa-trash"/></a>
                           <a :title="employee.status ? 'Mark as Inactive' : 'Mark as Active'"
                           class="btn btn-outline-info"
                           @click="updateStatus(employee, index)">
                          <i :class="employee.status ? 'fas fa-user-slash' : 'fas fa-user'"/>
                        </a>
                      </div>
                      <!-- <div class="btn-group btn-group-sm">
                        <a title="Delete"
                           href="#"
                           class="btn btn-danger ml-3"
                           @click="deleteEmployee(employee, index)"><i class="fas fa-trash"/></a>
                      </div> -->
                      <!-- <div class="btn-group btn-group-sm">
                        <a :title="employee.status ? 'Mark as Inactive' : 'Mark as Active'"
                           class="btn btn-outline-info ml-3"
                           @click="updateStatus(employee, index)">
                          <i :class="employee.status ? 'fas fa-user-slash' : 'fas fa-user'"/>
                        </a>
                      </div> -->
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <td colspan="8">
                    <div class="text-center">
                      <strong>No Results Found</strong>
                    </div>
                  </td>
                </tfoot>
              </table>
              </div>

              <section class="text-center">
                <div class="table-responsive-sm">
                  <pagination class="mt-3"
                              @pagination-change-page="pageNavigated"
                              :data="response"
                              :show-disabled="true"
                              :limit="15"/>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>

    <employee-add-modal
      :show-modal="employeeAddModalIsVisible"
      :auth-user="authUser"
      @closeEmployeeAddModal="closeEmployeeAddModal"
    />

    <employee-edit-modal
      :initial="selectedEmployee"
      :show-modal="employeeEditModalIsVisible"
      :auth-user="authUser"
      @closeEmployeeEditModal="closeEmployeeEditModal"
      @generatePin="generatePin"
      :pin="pin"
    />
  </div>
</template>


<script>
import EmployeeAddModal from '@/js/KitchenHand/Components/Employee/EmployeeAddModal';
import EmployeeEditModal from '@/js/KitchenHand/Components/Employee/EmployeeEditModal';
import Form from 'laravel-form-validation';
import Pagination from 'laravel-vue-pagination';
import dayjs from 'dayjs';

export default {
  name: 'EmployeeIndex',
  components: {
    EmployeeAddModal,
    EmployeeEditModal,
    Pagination,
  },
  props: {
    initial: {
      type: Object
    },
    authUser: {
      type: Object
    }
  },
  computed: {
    employees() {
      return this.response.data;
    }
  },
  methods: {
    showEmployeeAddModal() {
      this.employeeAddModalIsVisible = true;
    },

    closeEmployeeAddModal(employee) {
      this.employeeAddModalIsVisible = false;
      if (employee) {
        this.employees.push(employee);
        this.fetch()
      }
    },

    closeEmployeeEditModal(employee, index) {
      this.employeeEditModalIsVisible = false;
      if (employee) {
        this.$set(this.employees, index, Object.assign(this.employees[index], employee));
        this.fetch()
      }
    },

    showEmployeeEditModal(employee, index) {
      this.selectedEmployee = Object.assign({}, employee, {
        _index:  index
      });
      this.employeeEditModalIsVisible = true
    },

    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },

    fetch() {
      this.processing = true;
      this.$http.get(route('admin.kitchen.employee.index'), {
        params: this.params
      })
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          this.$toast.error('Something went wrong');
        })
        .finally(() => {
          this.processing = false;
        })
    },

    generatePin() {
      this.processing = true;
      this.$http.get(route('admin.kitchen.employee.generate-pin'))
        .then((response) => {
          if (response.status === 200) {
            this.pin = response.data;
            console.log(this.pin);
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error('Something went wrong')
        })
        .finally(() => {
          this.processing = false
        })
    },

    updateStatus(employee, index) {
      this.form.put(route('admin.kitchen.employee.update-status', employee.id))
        .then((response) => {

          this.$set(this.employees, index, Object.assign(this.employees[index], response));

          this.$toast.success('Employee status updated successfully');
        })
        .catch((error) => {
          this.$toast.error('Something went wrong')
        })
        .finally(() => {
          this.procesing = false
        })
    },

    async deleteEmployee(employee, index) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this employee?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if (!confirm) {
        return;
      }

      this.form.delete(route('admin.kitchen.employee.destroy', employee.id))
        .then(() => {
          this.employees.splice(index, 1);
          this.pageNavigated(1)
          this.$toast.success('Employee has been deleted')
        })
        .catch(error => {
          console.error(error.message)
        })

    },

    formatDateTime(dateTime) {
      return dayjs(dateTime).format('YYYY-MM-DD')
    }
  },
  data() {
    return {
      params: {
        page: 1,
        search: '',
        per_page: 15
      },
      form: new Form(),
      processing: false,
      employeeAddModalIsVisible: false,
      employeeEditModalIsVisible: false,
      response: this.initial,
      //employees: this.initial.data,
      selectedEmployee: {},
      pin:'wemdbj'
    }
  }
}
</script>
