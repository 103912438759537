<template>
    <b-tab class="bg-white shadow-sm" @click.once="fetch">
        <template #title>
            <i class="fas fa-file-invoice-dollar"></i> Invoices
        </template>
        <section class="table-responsive mb-3">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Invoice</th>
                    <th>Amount (in $)</th>
                    <th>Status</th>
                    <th>Paid at</th>
                    <th>Period</th>
                </tr>
                </thead>
                <tbody v-if="invoices.length">
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td>
                            <a :href="invoice.invoice_pdf" target="_blank">
                                <span class="fas fa-file-pdf"/> {{ getProductName(invoice.lines.data[0].plan.product) }}
                            </a>
                        </td>
                        <td>{{ centsToDollar(invoice.amount_paid) | currency }}</td>
                        <td>{{ invoice.status }}</td>
                        <td>{{ formatDateTime(invoice.status_transitions.paid_at) }}</td>
                        <td>{{ invoicePeriod(invoice.lines.data[0].period.start, invoice.lines.data[0].period.end) }}</td>
                    </tr>
                </tbody>
                <tfoot v-else>
                    <tr>
                        <td colspan="5" class="text-center">
                            <span class="fas fa-spinner fa-spin fa-3x"></span>
                            <h4 class="mt-2">Loading...</h4>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </section>
    </b-tab>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration);

export default {
    name: "InvoiceTab",
    data() {
        return {
            processing: false,
            response: {},
            admin: this.initial,
            product: null
        }
    },
    methods: {
        fetch() {
            this.processing = true;
            this.$http.get(route('admin.account.invoices'))
                .then((response) => {
                    this.response = response.data;
                })
                .catch((error) => {
                    this.$toast.error('Something went wrong');
                })
                .finally(() => {
                    this.processing = false;
                })
        },
        invoicePeriod (periodStart, periodEnd) {
            let startDate = new Date(periodStart*1000);
            let endDate =  new Date(periodEnd*1000);

            return `${dayjs.utc(startDate).tz(dayjs.tz.guess()).format('YYYY-MM-DD')} to
            ${dayjs.utc(endDate).tz(dayjs.tz.guess()).format('YYYY-MM-DD')}`;
        },
        centsToDollar(amount) {
            return amount/100;
        },
        formatDateTime (dateTime) {
          let paidAt = new Date(dateTime*1000);
          return dayjs.utc(paidAt).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
        },
      getProductName(id) {
          return this.products.find(product => product.code === id) ? this.products.find(product => product.code === id).name : 'Market Square' ;
      }
    },
    computed: {
        invoices() {
            return this.response.invoices ? this.response.invoices.data : [] || [];
        },
        products() {
          return this.response.products || [];
        }
    }
}
</script>
